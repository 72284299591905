import Select from 'react-select'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {useEffect, useState} from "react";
import {sub} from "date-fns";
import {ApiConsumer} from "api/ApiConsumer";
import {API_ROUTES} from "routes/api";

const search_types = [
    {
        value: 'title',
        label: 'Title',
    },
    {
        value: 'artist',
        label: 'Artist',
    },
    {
        value: 'label',
        label: 'Label',
    },
    {
        value: 'id',
        label: 'Song Id',
    },
]
const type = [
    {
        label: 'Recognised',
        value: 'recognised'
    },
    {
        label: 'Unrecognised',
        value: 'unrecognised'
    }
]
export default function BroadcastFilter({details, setDetails}) {
    const [channels, setChannels] = useState([])

    const dateChange = (dates) => {
        const [start, end] = dates

        setDetails((prevState) => ({
            ...prevState,
            [`start_date`]: start,
        }))
        setDetails((prevState) => ({
            ...prevState,
            [`end_date`]: end,
        }))
    }

    useEffect(() => {
        setDetails((prevState) => ({
            ...prevState,
            [`start_date`]: sub(new Date(), {months: 1}),
        }))

        setDetails((prevState) => ({
            ...prevState,
            [`end_date`]: new Date(),
        }))

        ApiConsumer.get(API_ROUTES.BROADCAST.CHANNELS)
            .then(res => {
                let temp = []
                res.data.map(item => temp.push({value: item.id, label: item.name}))
                setChannels(temp)
            })
            .catch(err => console.error(err))
    }, [])
    return <>
        <div className="box">
            <div className="box-wrapper">
                <h3 className='mb-2 font-semibold profile-overview-title'>Filters</h3>
                <div className="bg-white rounded p-3 shadow-lg">
                    <div className="border-b border-gray-200 grid grid-cols-12 space-x-4 pb-3">
                        <div className="col-span-8 w-full">
                            <label className="text-sm" htmlFor="stations">
                                Search Criteria:
                            </label>
                            <input
                                id="search"
                                name="search"
                                type='text'
                                placeholder="Type search term here..."
                                className={`py-2.5 px-4 block shadow-sm border-gray-300 rounded-md text-xs w-full border`}
                                onChange={(event) => {
                                    setDetails((prevState) => ({
                                        ...prevState,
                                        [`term`]: event.target.value
                                    }))
                                }}
                                value={details?.term}
                            />
                        </div>

                        <div className="col-span-4">
                            <label className="text-sm" htmlFor="stations">
                                Search Type:
                            </label>
                            <Select
                                id="search_type"
                                name="search_type"
                                placeholder="No Types"
                                isMulti
                                options={search_types}
                                classNamePrefix="multi-select"
                                className={`multi-select text-xs w-full`}
                                onChange={(event) => {
                                    setDetails((prevState) => ({
                                        ...prevState,
                                        [`term_types`]: [...event].map(
                                            (option) => option.value,
                                        ),
                                    }))
                                }}
                                defaultValue={0}
                                value={
                                    Object.keys(search_types).length !== 0
                                        ? details?.term_types?.map((selectedOption) => {
                                            return search_types[
                                                search_types.findIndex(
                                                    (option) => option.value === selectedOption,
                                                )
                                                ]
                                        })
                                        : 0
                                }
                            />
                        </div>
                    </div>

                    <div className="border-b border-gray-200 grid grid-cols-12 space-x-4 pb-3">
                        <div className="col-span-4 w-full">
                            <label className="text-sm" htmlFor="stations">
                                Stations:
                            </label>
                            <Select
                                id="stations"
                                name="stations"
                                placeholder="All stations"
                                isMulti
                                options={channels}
                                classNamePrefix="multi-select"
                                className={`multi-select text-xs w-full`}
                                onChange={(event) => {
                                    setDetails((prevState) => ({
                                        ...prevState,
                                        [`stations`]: [...event].map(
                                            (option) => option.value,
                                        ),
                                    }))
                                }}
                                value={
                                    Object.keys(channels).length !== 0
                                        ? details?.stations?.map((selectedOption) => {
                                            return channels[
                                                channels.findIndex(
                                                    (option) => option.value === selectedOption,
                                                )
                                                ]
                                        })
                                        : 0
                                }
                            />
                        </div>

                        <div className="col-span-4">
                            <label className="text-sm" htmlFor="mission_types">
                                Recognition Type:
                            </label>
                            <Select
                                id="mission_types"
                                name="mission_types"
                                placeholder="Recognised & Unrecognised"
                                isMulti
                                options={type}
                                classNamePrefix="multi-select"
                                className={`multi-select text-xs w-full`}
                                onChange={(event) => {
                                    setDetails((prevState) => ({
                                        ...prevState,
                                        [`type`]: [...event].map(
                                            (option) => option.value,
                                        ),
                                    }))
                                }}
                                defaultValue={0}
                                value={
                                    Object.keys(type).length !== 0
                                        ? details?.type?.map((selectedOption) => {
                                            return type[
                                                type.findIndex(
                                                    (option) => option.value === selectedOption,
                                                )
                                                ]
                                        })
                                        : 0
                                }
                            />
                        </div>

                        <div className="col-span-4">
                            <label className="text-sm" htmlFor="date_range">
                                Date Range:
                            </label>
                            <DatePicker
                                onKeyDown={(e) => {
                                    e.preventDefault()
                                }}
                                dateFormat="dd MMMM yyyy"
                                selected={details?.start_date}
                                onChange={(event) => dateChange(event)}
                                className="form-field w-full border rounded p-1.5 border-gray-300"
                                startDate={details?.start_date}
                                endDate={details?.end_date}
                                selectsRange
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
