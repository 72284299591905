import {useEffect, useContext} from 'react'
import {CrumbsContext} from 'context/CrumbsContext'
import {PageTitleContext} from 'context/PageTitleContext'
import icon from "assets/images/purple-icon.svg";
import {DocumentIcon, QuestionMarkCircleIcon, VideoCameraIcon} from "@heroicons/react/solid";

export default function UserGuide() {
    let {setTitle} = useContext(PageTitleContext)
    let {setCrumbs} = useContext(CrumbsContext)

    useEffect(() => {
        setTitle('User Guide')
        setCrumbs([{'name': 'Administration', 'link': '/monitoring'}, {'name': 'User Guide', 'link': '/venue'}])
//        setLoading(true)

    }, [])

    return <>
        <div className='h-screen px-5 py-5 mt-0'>
            <div className='grid grid-cols-1 sm:grid-cols-6 gap-4 mt-5'>
                <a href='https://cosbots-training.imonitor.live' target='_blank' rel='noreferrer'
                    className="bg-white shadow-lg rounded p-3 sm:col-span-2 xl:col-span-1 p-3 hover:shadow-2xl hover:border-2 border-transparent border-2 hover:border-green-600 cursor-pointer">
                    <div className="group relative bg-blue-500 rounded">
                        <DocumentIcon className={'md:w-full block rounded text-green-600 bg-gray-600'}/>
                        <div
                            className="absolute bg-black rounded bg-opacity-0 group-hover:bg-opacity-60 w-full h-full top-0 flex items-center group-hover:opacity-100 transition justify-evenly">
                            <button
                                className="hover:scale-110 text-white opacity-0 transform translate-y-3 group-hover:translate-y-0 group-hover:opacity-100 transition">
                                <DocumentIcon className={'w-12 text-green-600'}/>
                            </button>
                        </div>
                    </div>
                    <div className="pt-3">
                        <h3 className="text-gray-600 text-lg text-center hover:font-bold">User Guide</h3>
                    </div>
                </a>

                <a href='https://www.youtube.com/playlist?list=PLs2c-Bb53cWbGtsIZtSObIVZLQ82E5SvY' target='_blank' rel='noreferrer'
                   className="bg-white shadow-lg rounded p-3 sm:col-span-2 xl:col-span-1 p-3 hover:shadow-2xl hover:border-2 border-transparent border-2 hover:border-red-600 cursor-pointer">
                    <div className="group relative">
                        <VideoCameraIcon className={'md:w-full block rounded text-red-600 bg-gray-600'}/>
                        <div
                            className="absolute bg-black rounded bg-opacity-0 group-hover:bg-opacity-60 w-full h-full top-0 flex items-center group-hover:opacity-100 transition justify-evenly">
                            <button
                                className="hover:scale-110 text-white opacity-0 transform translate-y-3 group-hover:translate-y-0 group-hover:opacity-100 transition">
                                <VideoCameraIcon className={'w-12 text-red-600'}/>
                            </button>
                        </div>
                    </div>
                    <div className="pt-3">
                        <h3 className="text-gray-600 text-lg text-center hover:font-bold">Video Guides</h3>
                    </div>
                </a>

                <div
                    className="bg-white shadow-lg rounded p-3 sm:col-span-2 xl:col-span-1 hover:shadow-2xl hover:border-2 border-transparent border-2 hover:border-blue-500 cursor-pointer">
                    <a href='mailto:cosbots@broadsmart.co.za'>
                        <div className="group relative">
                            <QuestionMarkCircleIcon className={'md:w-full block rounded text-blue-600 bg-gray-600'}/>
                            <div
                                className="absolute bg-black rounded bg-opacity-0 group-hover:bg-opacity-60 w-full h-full top-0 flex items-center group-hover:opacity-100 transition justify-evenly">
                                <button
                                    className="hover:scale-110 text-white opacity-0 transform translate-y-3 group-hover:translate-y-0 group-hover:opacity-100 transition">
                                    <QuestionMarkCircleIcon className={'w-12 text-blue-600'}/>
                                </button>
                            </div>
                        </div>
                    </a>
                    <div className="pt-3">
                        <h3 className="text-gray-600 text-lg text-center hover:font-bold">Log Requests</h3>
                    </div>
                </div>

            </div>
        </div>
    </>
}
