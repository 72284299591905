import React from 'react';
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

Sentry.init({
    dsn: "https://11eacb056f6c48839c3abe143e40a4ac@o86758.ingest.sentry.io/6303774",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
});

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(<App tab="app"/>);

reportWebVitals();
