import ScanUpload from "../components/Upload";
import {useContext, useEffect} from "react";
import {PageTitleContext} from "context/PageTitleContext";
import {CrumbsContext} from "context/CrumbsContext";

export default function Scan() {
    let {setTitle} = useContext(PageTitleContext)
    let {setCrumbs} = useContext(CrumbsContext)

    useEffect(() => {
        setTitle('Offline Scan')
        setCrumbs([{'name': 'Monitoring', 'link': '/monitoring'}, {'name': 'Offline Scan', 'link': '/scan'}])
    }, [])

    return <>
        <div className="min-h-full px-5 py-0 mt-0 h-screen">
            <div className="mt-5">
                <ScanUpload/>
            </div>
        </div>
    </>
}
