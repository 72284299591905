import {PageTitleContext} from "context/PageTitleContext";
import {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {BroadcastTable} from "components/Broadcast/table";
import {BroadcastHeader} from "components/Broadcast/header";
import {ApiConsumer} from "api/ApiConsumer";
import {API_ROUTES} from "routes/api";
import {CrumbsContext} from "context/CrumbsContext";
import {TopCharts} from "components/Charts/top";
import {StackedLine} from "components/Charts/stacked-line";
import {Doughnut} from "components/Charts/doughnut";

export default function Broadcast() {
    const [data, setData] = useState([])
    const [channel, setChannel] = useState({})
    const [loading, setLoading] = useState(true)
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1)
    let {setTitle} = useContext(PageTitleContext)
    setTitle('Broadcast Monitoring')

    let {id} = useParams();

    function stream() {
        ApiConsumer.get(API_ROUTES.BROADCAST.CHANNEL(id))
            .then(res => {
                setChannel(res.data)
                setCrumbs([{'name': 'Monitoring', 'link': '/monitoring'}, {
                    'name': 'Broadcast Monitoring',
                    'link': '/monitoring'
                }, {
                    'name': res.data.name,
                    'link': `/monitoring/${res.data.id}`
                }])
            })
            .catch(err => {
                console.error(err)
            })
    }

    function broadcast() {

        ApiConsumer.get(API_ROUTES.BROADCAST.MONITORING(id))
            .then(res => {
                setTotal(res.data.count)
                setData(res.data.data)
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => setLoading(false))
    }

    let {setCrumbs} = useContext(CrumbsContext)


    useEffect(() => {
        setTitle('Broadcast Monitoring')
        setCrumbs([{'name': 'Monitoring', 'link': '/monitoring'}, {
            'name': 'Broadcast Monitoring',
            'link': '/monitoring'
        }])
        stream()
    }, [])

    useEffect(() => {
        broadcast()
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, [id, page])

    return <>
        <div className="min-h-full px-5 py-5 mt-0">
            <BroadcastHeader broadcast={channel}/>
            <section className="grid grid-cols-1 xl:grid-cols-1 2xl:grid-cols-4 gap-4 mt-5">
                <div className="col-span-2">
                    <h3 className='mb-2 font-semibold profile-overview-title'>Recognition by Month</h3>
                    <StackedLine url={API_ROUTES.BROADCAST.REPORTS.COMPARISON.BY_MONTH_PER_STATION(id)}
                                 title={'right'}/>
                </div>
                <div className="col-span-1">
                    <h3 className='mb-2 font-semibold profile-overview-title'>Unrecognised vs Recognised</h3>
                    <Doughnut url={API_ROUTES.BROADCAST.REPORTS.COMPARISON.ALL_BY_STATION(id)}/>
                </div>
                <div className="col-span-1">
                    <h3 className='mb-2 font-semibold profile-overview-title'>International vs National</h3>
                    <Doughnut url={API_ROUTES.BROADCAST.REPORTS.COMPARISON.LOCATION_BY_STATION(id)}/>
                </div>
            </section>
            <section className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-3 mb-5">
                <div className="sm:col-span-1">
                    <h3 className='mb-2 font-semibold profile-overview-title'>Top 5 Artists</h3>
                    <TopCharts url={API_ROUTES.BROADCAST.REPORTS.TOP_ARTISTS_BY_BROADCAST(id)} limit={5}
                               name={'Artist'}/>
                </div>
                <div className="sm:col-span-1">
                    <h3 className='mb-2 font-semibold profile-overview-title'>Top 5 Labels</h3>
                    <TopCharts url={API_ROUTES.BROADCAST.REPORTS.TOP_LABELS_BY_BROADCAST(id)} limit={5} name={'Label'}/>
                </div>
            </section>
            <h3 className='mb-2 font-semibold profile-overview-title mt-5'>Previous 7 days results</h3>
            <BroadcastTable data={data} loading={loading} currentPage={page} setPage={setPage} total={total}/>
        </div>
    </>
}
