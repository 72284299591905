import {DownloadIcon, PlayIcon} from "@heroicons/react/outline";
import {MusicSlideOverContext} from "context/MusicSlideOverContext";
import {useContext} from "react";
import Loader from "components/Loader";
import icon from "assets/images/music-icon.svg";
import {MusicPlayerContext} from "context/MusicPlayer";
import {useUtils} from "hooks/UtilityHook";


export function ResultsTable({loading = true, data = [], currentPage, setPage, total, pages = 5, query = false}) {
    let {setMusic} = useContext(MusicSlideOverContext)
    const {setPlaying} = useContext(MusicPlayerContext);
    let {downloadFile} = useUtils()

    return <>
        {loading ? <Loader upload_message={'loading'} full_height={true}/> :
            <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                <tr>
                    <th scope="col"
                        className="py-3.5 pl-2 pr-3 text-left text-sm font-semibold text-blue-500 sm:pl-6">
                        Title
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Station
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Play
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Download
                    </th>
                </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                {
                    data.map(track => (
                        <tr key={track?.id} className={'cursor-pointer'}>
                            <td className="whitespace-nowrap py-4 pl-1 pr-3 text-sm font-medium text-blue-500 sm:pl-6"
                                onClick={() => {
                                    setMusic({...track, ...{open: true}})
                                }}>
                                {track.title}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{track?.broadcast_stream?.name}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                <PlayIcon className={'h-5 w-5 cursor-pointer text-green-500'}
                                          onClick={() => {
                                              let music = {
                                                  music: {
                                                      id: track.id,
                                                      image: {icon},
                                                      title: track.title,
                                                      artist: track.artist,
                                                      file: track.file ?? track?.recording?.recording
                                                  }
                                              }
                                              setPlaying(music)
                                          }}/>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                <DownloadIcon onClick={() => {
                                    downloadFile(track.title, track.file ?? track?.recording?.recording)
                                }}
                                              className={'h-5 w-5 cursor-pointer text-blue-500'}/>
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        }
        {
            !loading ? data.length === 0 ?
                <div className={'h-screen text-center overflow-hidden overflow-y-hidden overflow-x-hidden'}>
                    No results
                </div> : null : null
        }
    </>
}
