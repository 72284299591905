import { Tab } from '@headlessui/react'
import BulkUpload from 'components/DigitalLibrary/BulkUpload'
import AddLibraryItem from 'components/DigitalLibrary/LibraryItem'
import { Fragment } from 'react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
export default function MusicUpload() {

    return (
        <div className="w-full mx-auto lg:max-w-none lg:mt-0 lg:col-span-4">
            <Tab.Group as="div">
                <div>
                    <Tab.List className="-mb-px flex space-x-1">
                        <Tab className={({ selected }) =>
                            classNames(
                                selected ? 'bg-white text-gray-800' : 'text-gray-600 hover:text-gray-800 hover:bg-white',
                                'px-4 py-4 font-medium text-sm rounded-t')}>
                            Single Upload
                        </Tab>

                        <Tab className={({ selected }) =>
                            classNames(
                                selected ? 'bg-white text-gray-800' : 'text-gray-600 hover:text-gray-800 hover:bg-white',
                                'px-4 py-4 font-medium text-sm rounded-t')}>
                            Bulk Upload
                        </Tab>
                    </Tab.List>
                </div>

                <Tab.Panels as={Fragment}>
                    <Tab.Panel className="-mb-10">
                        <h3 className="sr-only">Single Upload</h3>

                        <AddLibraryItem />
                    </Tab.Panel>

                    <Tab.Panel as="dl" className="-mb-10">
                        <h3 className="sr-only">Bulk Upload</h3>

                        <BulkUpload />
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </div>
    );
}
