import {useEffect, useState} from "react";
import {ApiConsumer} from "api/ApiConsumer";
import {MusicChart} from "components/Music/chart";

export function ChartTable({url}) {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setLoading(true)
        ApiConsumer.get(url)
            .then(res => {
                setData(res.data)
            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    }, [])

    return <>
        <MusicChart list={data} limit={5} loading={loading} report={true}/>
    </>
}
