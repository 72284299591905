import {Tab} from '@headlessui/react'
import {Fragment} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React, {useState, useContext} from 'react'
import {
    faCalendarAlt,
    faHeadphonesAlt,
    faVideo,
    faTv,
    faFileLines
} from '@fortawesome/free-solid-svg-icons'
import FileUpload from 'components/FileUpload'
import {ApiConsumer} from "api/ApiConsumer";
import {API_ROUTES} from "routes/api";
import Loader from "components/Loader";
import {useHistory} from "react-router-dom";
import ScanUploadFile from "components/ScanUpload";
import {NotificationContext} from "context/NotificationContext";
import {useAuthentication} from "hooks/AuthenticationHook";
import {ResultsTable} from "components/ScanUpload/table";

export default function ScanUpload() {
    const [details, setDetails] = useState({name: "", episode_no: "", date: "", channel: "", season_no: ""})
    const [title, setTitle] = useState('')
    const [program_name, setProgramName] = useState('')
    const [season_number, setSeasonNumber] = useState('')
    const [episode_number, setEpisodeNumber] = useState('')
    const [channel, setChannel] = useState('')
    const [upload_enabled, setUploadEnabled] = useState(false)
    const [upload_progress, setUploadProgress] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [upload_message, setUploadMessage] = useState('Uploading your content...')
    const [file, setFile] = useState({})

    let history = useHistory()
    let {setNotification} = useContext(NotificationContext)
    let {user} = useAuthentication()


    function handleProgressUpdate(progress) {
        setUploadProgress(progress)
        if (progress === 100) {
            setUploadMessage('Upload is being processed. Please check your email for final results.')
        }
    }

    function scan(e) {
        e.preventDefault()
        let data = new FormData()
        if (file != null)
            data.append('file', file)
        data.append('user_id', user()?.id)
        setIsLoading(true)

        const config = {
            onUploadProgress: function (progressEvent) {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadProgress(percentCompleted);
            }
        }

        ApiConsumer.post(API_ROUTES.BROADCAST.SCAN, data, config)
            .then(() => {
                setNotification({
                    show: true,
                    title: 'Upload successful',
                    message: `Upload successful, please check your email for final result.`,
                    type: 'success'
                })
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <div className='w-full max-w-2xl mx-auto mt-16 lg:max-w-none lg:mt-0 lg:col-span-4'>
            <form className='min-h-full rounded px-5 py-5 mt-0 bg-white text-gray-800' onSubmit={scan}>
                <div className="grid grid-cols-12 gap-4">
                    <div className='col-span-6'>
                        <h3 className='mb-2 font-semibold profile-overview-title flex mx-full mb-4'>
                            <span className="min-w-0 flex-1 items-center">Uploaded New Scan</span>
                        </h3>
                        <div className='flex rounded-md shadow-sm sm:col-span-3'>
                        <span
                            className='inline-flex items-center px-3 rounded-l-md bg-gray-300 text-gray-500 sm:text-sm'>
                            <FontAwesomeIcon icon={faTv} className='h-4 w-4' aria-hidden='true'/>
                        </span>
                            <select
                                id="music-type"
                                name="music-type"
                                onChange={e => setChannel(e.target.value)}
                                placeholder="Music Type"
                                required={false}
                                className="flex-1 min-w-0 block w-full px-3 py-2 bg-gray-100 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                <option value="Event Recording">Event Recording</option>
                                <option value="Venue Recording">Venue Recording</option>
                                <option value="Broadcast Recording">Broadcast Recording</option>
                                <option value="Video on Demand">Video on Demand</option>
                                <option value="Podcast">Podcast</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        <div className='flex rounded-md shadow-sm sm:col-span-3 mt-3'>
                        <span
                            className='inline-flex items-center px-3 rounded-l-md bg-gray-300 text-gray-500 sm:text-sm'>
                            <FontAwesomeIcon icon={faVideo} className='h-4 w-4' aria-hidden='true'/>
                        </span>
                            <input
                                id='input-program-name'
                                name='program-name'
                                className='flex-1 min-w-0 block w-full px-3 py-2 bg-gray-100 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                type='text'
                                placeholder='Program Name*'
                                required={true}
                                value={program_name}
                                onChange={e => setProgramName(e.target.value)}
                            />
                        </div>
                        <div className='flex rounded-md shadow-sm sm:col-span-3 mt-3'>
                        <span
                            className='inline-flex items-center px-3 rounded-l-md bg-gray-300 text-gray-500 sm:text-sm'>
                            <FontAwesomeIcon icon={faHeadphonesAlt} className='h-4 w-4' aria-hidden='true'/>
                        </span>
                            <input
                                id='input-season-number'
                                name='season-number'
                                className='flex-1 min-w-0 block w-full px-3 py-2 bg-gray-100 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                type='text'
                                placeholder='Season Number (optional)'
                                required={false}
                                value={season_number}
                                onChange={e => setSeasonNumber(e.target.value)}
                            />
                        </div>
                        <div className='flex rounded-md shadow-sm sm:col-span-3 mt-3'>
                        <span
                            className='inline-flex items-center px-3 rounded-l-md bg-gray-300 text-gray-500 sm:text-sm'>
                            <FontAwesomeIcon icon={faHeadphonesAlt} className='h-4 w-4' aria-hidden='true'/>
                        </span>
                            <input
                                id='input-episode-number'
                                name='episode-number'
                                className='flex-1 min-w-0 block w-full px-3 py-2 bg-gray-100 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                type='text'
                                placeholder='Episode Number (optional)'
                                required={false}
                                value={episode_number}
                                onChange={e => setEpisodeNumber(e.target.value)}
                            />
                        </div>
                        <div className='flex rounded-md shadow-sm sm:col-span-3 mt-3'>
                        <span
                            className='inline-flex items-center px-3 rounded-l-md bg-gray-300 text-gray-500 sm:text-sm'>
                            <FontAwesomeIcon icon={faCalendarAlt} className='h-4 w-4' aria-hidden='true'/>
                        </span>
                            <input
                                id='input-air-date'
                                name='air-date'
                                className='flex-1 min-w-0 block w-full px-3 py-2 bg-gray-100 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                type='date'
                                placeholder='Air Date'
                                required={true}
                                value={details?.air_date}
                                onChange={e => setTitle(e.target.value)}
                            />
                        </div>
                        <div className='flex rounded-md sm:col-span-3 sm:row-span-5 text-center mt-3'>
                            <ScanUploadFile upload_progress={upload_progress} icon={faFileLines} icon_size='4x'
                                            setFile={setFile} message={'Click or drag file to this area to upload (MAX 500mb)'}/>
                        </div>
                        <div className="mt-1 flex rounded-md sm:col-span-1 float-right mt-4">
                            <button type="submit"
                                    className="float-right inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500s cursor-pointer">
                                Submit
                            </button>
                        </div>
                        {
                            isLoading ? <Loader upload_message={upload_message}/> : ''
                        }
                    </div>
                    <div className='col-span-6'>
                        <h3 className='mb-2 font-semibold profile-overview-title flex mx-full mb-4'>
                            <span className="min-w-0 flex-1 items-center">Previously Uploaded Files</span>
                        </h3>
                        <ResultsTable/>
                    </div>
                </div>

            </form>

        </div>
    );
}
