import {useContext, useEffect, useRef, useState} from "react";
import {PageTitleContext} from "context/PageTitleContext";
import {CrumbsContext} from "context/CrumbsContext";
import {ApiConsumer} from "api/ApiConsumer";
import {API_ROUTES} from "routes/api";
import CustomFilter from "components/Reports/CustomFilter";
import {DocumentDownloadIcon, SearchIcon} from "@heroicons/react/outline";
import {CustomTable} from "components/Reports/CustomTable";
import {MonthlyCharts} from "components/Charts/monthly-charts";
import {useUtils} from "hooks/UtilityHook";

export default function CustomReport() {
    const [details, setDetails] = useState({chart_order: 'none'})
    const [loading, setLoading] = useState(false)
    const [graphicLoading, setGraphicLoading] = useState(false)
    const [results, setResults] = useState([])
    let {setTitle} = useContext(PageTitleContext)
    let {setCrumbs} = useContext(CrumbsContext)
    let {exportAsImage} = useUtils();
    const exportRef = useRef();

    function searchReport() {
        setLoading(true)
        ApiConsumer.get(API_ROUTES.BROADCAST.REPORTS.COMPARISON.CATALOGUE, {
            params: {...details},
            paramSerializer: function (params) {
                return JSON.stringify(params)
            }
        })
            .then(res => {
                setResults(res.data)
            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    }

    function downloadReport() {
        setLoading(true)
        ApiConsumer.get(API_ROUTES.BROADCAST.REPORTS.COMPARISON.CATALOGUE_DOWNLOAD, {
            params: {...details},
            paramSerializer: function (params) {
                return JSON.stringify(params)
            }, responseType: 'blob'
        })
            .then(({data}) => downloadExcelDocument('Catalogue Report.xlsx', data))
            .catch(err => console.error(err))
            .finally(() => {
                setGraphicLoading(true)
                setLoading(false)
            })
    }

    function getMonthlyStats() {
        setGraphicLoading(true)
        ApiConsumer.get(API_ROUTES.BROADCAST.REPORTS.GRAPHICAL, {
            params: {...details},
            paramSerializer: function (params) {
                return JSON.stringify(params)
            }
        })
            .then(res => {
                setResults(res.data)
            })
            .catch(err => console.error(err))
            .finally(() => {
                setGraphicLoading(false)
                setLoading(true)
            })
    }

    const downloadExcelDocument = (name, data) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    function downloadImageReport() {
        function padTo2Digits(num) {
            return num.toString().padStart(2, '0');
        }

        function formatDate(date) {
            return [
                padTo2Digits(date.getDate()),
                padTo2Digits(date.getMonth() + 1),
                date.getFullYear(),
            ].join('-');
        }

        exportAsImage(exportRef.current, `COSBOTS - Graphical Report ${formatDate(details?.start_date)} to ${formatDate(details?.end_date)}`)
    }

    useEffect(() => {
        setTitle('Custom Reports')
        setCrumbs([{'name': 'Charts & Reports', 'link': '/reports/custom'}, {
            'name': 'Custom Reports',
            'link': '/reports/custom'
        }])
    }, [])

    return <div className="min-h-full px-5 py-5 mt-0">
        <CustomFilter details={details} setDetails={setDetails} title={'List Limit'} limit={true}/>
        <h3 className='mb-2 font-semibold profile-overview-title mt-5 flex mx-full'>
                <span className="min-w-0 flex-1 items-center">
                Results
              </span>
            <SearchIcon className="h-8 w-8 pt-1.5 cursor-pointer hover:text-green-500"
                        aria-hidden="true"
                        onClick={() => {
                            details?.type !== 'graphical' ? searchReport() : getMonthlyStats()
                        }}/>
            <DocumentDownloadIcon className="h-8 w-8 pt-1.5 cursor-pointer hover:text-green-500"
                                  aria-hidden="true"
                                  onClick={() => {
                                      details?.type !== 'graphical' ? downloadReport() : downloadImageReport()
                                  }}/>
        </h3>
        {
            details?.type !== 'graphical' ?
                <CustomTable list={results} loading={loading}/> :
                <div ref={exportRef}><MonthlyCharts results={results} loading={graphicLoading}/></div>
        }
    </div>
}
