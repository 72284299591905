import {MailIcon, CheckCircleIcon, PencilAltIcon, XIcon, XCircleIcon} from "@heroicons/react/outline"

export default function UserCard({user, showModal, showUserTypeModal}) {
    return <li key={user.email} className="col-span-1 bg-white rounded-lg shadow-lg divide-y divide-gray-200">
        <div className="w-full flex items-center justify-between p-6 space-x-6">
            <div className="flex-1 truncate">
                <div className="flex items-center space-x-3">
                    <h3 className="text-gray-900 text-sm font-medium truncate">{user.first_name} {user.last_name}</h3>
                    <span
                        className={`flex-shrink-0 inline-block px-2 py-0.5 ${user?.user_type?.name ? "bg-red-200" : "bg-green-100"} text-green-800 text-xs font-medium rounded-full`}>
                  {user?.user_type?.name ?? 'User'}
                </span>
                </div>
                <p className="mt-1 text-gray-500 text-sm truncate">{user.organisation?.name ?? 'No Organisation'}</p>
                <a href={`mailto:${user?.email}`}><p className="mt-1 text-gray-400 text-sm truncate">{user.email}</p></a>
            </div>
            {
                user?.is_active ?
                    <CheckCircleIcon className="w-10 h-10 bg-white rounded-full flex-shrink-0" color="#21ba45"/> :
                    <XCircleIcon className="w-10 h-10 bg-white rounded-full flex-shrink-0 text-red-600"/>
            }
        </div>
        <div>
            <div className="-mt-px flex divide-x divide-gray-200">
                <div className="w-0 flex-1 flex">
                    <div
                        onClick={(e) => {
                            showUserTypeModal(e, user)
                        }}
                        className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 cursor-pointer hover:text-blue-600 hover:font-bold"
                    >
                        <PencilAltIcon className="w-5 h-5 text-gray-400" aria-hidden="true"/>
                        <span className="ml-3">User Type</span>
                    </div>
                </div>
                <div className="-ml-px w-0 flex-1 flex">
                    <div
                        onClick={(e) => {
                            showModal(e, user)
                        }}
                        className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 cursor-pointer hover:font-bold hover:text-blue-600"
                    >
                        {
                            !user?.is_active ? <CheckCircleIcon className="w-5 h-5 bg-white rounded-full flex-shrink-0"
                                                                color="#21ba45"/> :
                                <XCircleIcon className="w-5 h-5 bg-white rounded-full flex-shrink-0 text-red-600"/>
                        }
                        <span className="ml-3">{!user?.is_active ? 'Activate' : 'Deactivate'}</span>
                    </div>
                </div>
            </div>
        </div>
    </li>
}
