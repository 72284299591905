import {MusicTable} from "components/Music/table";
import {useContext, useEffect, useState} from "react";
import {PageTitleContext} from "context/PageTitleContext";
import {BroadcastFilter} from "components/Broadcast/filter";
import {CrumbsContext} from "context/CrumbsContext";

export default function Library() {
    const [search, setSearch] = useState('')
    const [term, setTerm] = useState('')
    let {setTitle} = useContext(PageTitleContext)
    let {setCrumbs} = useContext(CrumbsContext)

    const types = [
        {
            label: 'Title',
            value: 'title'
        },
        {
            label: 'Artist',
            value: 'artist'
        },
        {
            label: 'Album',
            value: 'album'
        },
        {
            label: 'Label',
            value: 'label'
        },
        {
            label: 'Composer',
            value: 'composer'
        }
    ]

    function filterLibrary() {
        setTerm(search)
    }

    useEffect(() => {
        setTitle('Digital Library')
        setCrumbs([{'name': 'Library', 'link': '/library'}, {'name': 'Digital Library', 'link': '/library'}])
    }, [])

    return <>
        <div className="min-h-full px-5 py-5 mt-0">
            <BroadcastFilter search={filterLibrary} details={search} setDetails={setSearch} options={types}/>
            <MusicTable term={term} />
        </div>
    </>
}
