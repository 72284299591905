import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min';

function buildWaveformOptions(ref) {
    return {
        container: ref,
        waveColor: '#316ce8',
        progressColor: "#2cdd9b",
        cursorColor: "#8d97ad",
        barWidth: 3,
        barRadius: 3,
        responsive: true,
        height: 50,
        normalize: true,
        partialRender: true
    }
}

export const useWaveform = () => {
    return {buildWaveformOptions}
}
