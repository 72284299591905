import {NavLink, useHistory} from "react-router-dom";
import imonitor from 'assets/images/imonitor.svg'
import cosbots from 'assets/images/cosbots-logo.png'
import {useEffect, useState, useContext} from "react";
import axios from "axios";
import {API_ROUTES} from "routes/api";
import * as Sentry from '@sentry/react'
import Select from "react-select";
import {NotificationContext} from "context/NotificationContext";
import {CogIcon, EyeOffIcon} from "@heroicons/react/outline";
import {EyeIcon} from "@heroicons/react/solid";

export default function Access() {
    const [details, setDetails] = useState({})
    const [organisations, setOrganisations] = useState([])
    const [country, setCountry] = useState([])
    const [loading, setLoading] = useState(false)
    const [inputType, setInputType] = useState('password')

    let history = useHistory()
    let {setNotification} = useContext(NotificationContext)

    function register() {
        setLoading(true)
        axios.post(API_ROUTES.USERS.REGISTER, details)
            .then((res) => {
                setNotification({
                    show: true,
                    title: 'Registration successful, an email will be sent granting you access.',
                    type: 'success'
                })
                history.push('/')
            })
            .catch((err) => {
                setNotification({
                    show: true,
                    title: 'Login error',
                    message: 'Could not log you in, please try again later.',
                    type: 'error'
                })
                console.error(err)
                Sentry.captureException(err)
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        axios.get(API_ROUTES.COUNTRIES.ALL)
            .then(res => {
                let temp = []
                res.data.map(x => temp.push({label: x.name, value: x.id}))
                setCountry(temp)
            })
            .then(()=>{
                setDetails((prevState) => ({
                    ...prevState,
                    [`country`]: '15223426-94f1-41d5-8b45-eb35039205d6'
                }))
            })

        axios.get(API_ROUTES.ORGANISATIONS.ALL)
            .then(res => {
                let temp = []
                res.data.map(x => temp.push({label: x.name, value: x.id}))
                setOrganisations(temp)
            })
    }, [])

    return (
        <>
            <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        className="mx-auto h-12 w-auto"
                        src={imonitor}
                        alt="iMonitor Logo"
                    />
                    <img
                        className="mx-auto h-12 w-auto mt-5"
                        src={cosbots}
                        alt="COSBOTS Logo"
                    />
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Request Access to
                        iMonitor</h2>
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow-lg sm:rounded-lg sm:px-10 border-2">
                        <form className="space-y-6" onSubmit={(event) => {
                            event.preventDefault()
                            register()
                        }} method="POST">
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    First Name
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="first_name"
                                        name="first_name"
                                        type="text"
                                        required
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        onChange={(event) => {
                                            setDetails((prevState) => ({
                                                ...prevState,
                                                [`first_name`]: event.target.value,
                                            }))
                                        }}
                                        value={details?.first_name}
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
                                    Last Name
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="last_name"
                                        name="last_name"
                                        type="text"
                                        required
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        onChange={(event) => {
                                            setDetails((prevState) => ({
                                                ...prevState,
                                                [`last_name`]: event.target.value,
                                            }))
                                        }}
                                        value={details?.last_name}
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="contact_number" className="block text-sm font-medium text-gray-700">
                                    Contact Number
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="contact_number"
                                        name="contact_number"
                                        type="text"
                                        required
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        onChange={(event) => {
                                            setDetails((prevState) => ({
                                                ...prevState,
                                                [`contact_number`]: event.target.value,
                                            }))
                                        }}
                                        value={details?.contact_number}
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Email address
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        required
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        onChange={(event) => {
                                            setDetails((prevState) => ({
                                                ...prevState,
                                                [`email`]: event.target.value,
                                            }))
                                        }}
                                        value={details?.email}
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                    Organisation
                                </label>
                                <div className="mt-1">
                                    <Select
                                        id="graph_type"
                                        name="graph_type"
                                        placeholder="Select Organisation"
                                        isMulti={false}
                                        options={organisations}
                                        classNamePrefix="multi-select"
                                        className={`multi-select text-xs w-full`}
                                        onChange={(event) => {
                                            setDetails((prevState) => ({
                                                ...prevState,
                                                [`organisation`]: event.value
                                            }))
                                        }}
                                        defaultValue={''}
                                        value={organisations[
                                            organisations.findIndex(
                                                (option) => option.value === details?.organisation,
                                            )
                                            ]}
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                    Country
                                </label>
                                <div className="mt-1">
                                    <Select
                                        id="graph_type"
                                        name="graph_type"
                                        placeholder="Select Country"
                                        isMulti={false}
                                        options={country}
                                        classNamePrefix="multi-select"
                                        className={`multi-select text-xs w-full`}
                                        onChange={(event) => {
                                            setDetails((prevState) => ({
                                                ...prevState,
                                                [`country`]: event.value
                                            }))
                                        }}
                                        defaultValue={''}
                                        value={country[
                                            country.findIndex(
                                                (option) => option.value === details?.country,
                                            )
                                            ]}
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                    Password
                                </label>
                                <div className="mt-1">
                                    <div className="mt-2 flex rounded-md shadow-sm">
                                    <input
                                        id="password"
                                        name="password"
                                        type={inputType}
                                        required
                                        className="appearance-none block w-full px-3 py-2 border rounded-l-md rounded-r-none border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        onChange={(event) => {
                                            setDetails((prevState) => ({
                                                ...prevState,
                                                [`password`]: event.target.value,
                                            }))
                                        }}
                                        value={details.password}
                                    />
                                    <span
                                        className="cursor-pointer inline-flex items-center rounded-r-md border border-l-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                                          {
                                              inputType === 'password' ?
                                                  <EyeIcon onClick={()=>setInputType('text')} className={'h-4'} /> :
                                                  <EyeOffIcon onClick={()=>setInputType('password')} className={'h-4'}/>
                                          }
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <label htmlFor="confirm_password" className="block text-sm font-medium text-gray-700">
                                    Confirm Password
                                </label>
                                <div className="mt-1">
                                    <div className="mt-2 flex rounded-md shadow-sm">
                                    <input
                                        id="confirm_password"
                                        name="confirm_password"
                                        type={inputType}
                                        required
                                        className="appearance-none block w-full px-3 py-2 border rounded-l-md rounded-r-none border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        onChange={(event) => {
                                            setDetails((prevState) => ({
                                                ...prevState,
                                                [`confirm_password`]: event.target.value,
                                            }))
                                        }}
                                        value={details.confirm_password}
                                    />
                                    <span
                                        className="cursor-pointer inline-flex items-center rounded-r-md border border-l-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                                          {
                                              inputType === 'password' ?
                                                  <EyeIcon onClick={()=>setInputType('text')} className={'h-4'} /> :
                                                  <EyeOffIcon onClick={()=>setInputType('password')} className={'h-4'}/>
                                          }
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    {
                                        loading ? <CogIcon
                                                className="animate-spin h-6 w-6 content-center text-center mx-auto"/> :
                                            <>Request Access</>
                                    }
                                </button>
                            </div>
                            <div className="flex items-center justify-between text-center">
                                <div className="text-sm text-center w-full">
                                    <NavLink to={'/login'}
                                             className="font-medium text-center text-indigo-600 hover:text-indigo-500">
                                        Have an account? Click to login
                                    </NavLink>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
