import React, {useState} from "react"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faDeleteLeft, faInbox, faPaperclip} from '@fortawesome/free-solid-svg-icons'
import {Progress} from 'react-sweet-progress'
import 'react-sweet-progress/lib/style.css'
import {faTrashCan} from "@fortawesome/free-regular-svg-icons"
import {useContext} from "react";
import {NotificationContext} from "context/NotificationContext";
import {ApiConsumer} from "api/ApiConsumer";
import {API_ROUTES} from "routes/api";
import {useAuthentication} from "hooks/AuthenticationHook";

const _validFileExtensions = [".mp3", ".wav", ".mp4", ".mp4v"]

export default function ScanUploadFile({
                                           upload_progress,
                                           icon,
                                           icon_size,
                                           setFile,
                                           message = 'Click or drag file to this area to upload'
                                       }) {
    const [file_name, setFiles] = useState('')
    const [upload, setUpload] = useState(0)
    let {setNotification} = useContext(NotificationContext)
    let {user} = useAuthentication()

    function handleChange(e) {
        const name = e.target.name
        const value = e.target.files[0]
        setFiles(value.name)
        setFile(value)
    }

    function handleDelete() {
        setFiles('')
    }

    return (
        <div className="flex grid grid-cols-1 bg-white justify-center w-full">
            <div className='flex items-center justify-center w-full'>
                <label
                    className='flex flex-col w-full bg-white h-full border-2 border-dashed hover:bg-gray-100 hover:border-gray-300'>
                    <div className='flex flex-col items-center justify-center pt-7'>
                        <FontAwesomeIcon icon={icon} size={icon_size}/>
                        <p className='pt-2 text-base tracking-wider group-hover:text-gray-600'>
                            {message}
                        </p>
                    </div>
                    <input type='file' name='file' onChange={handleChange} className='opacity-0'/>
                </label>
            </div>

            {
                file_name == '' ? <div></div> : <div className="flex justify-between w-full p-2">
                    <div><p><span><FontAwesomeIcon icon={faPaperclip} className='pr-2'/></span>{file_name}</p></div>

                    <div><FontAwesomeIcon icon={faTrashCan} onClick={handleDelete}/></div>
                </div>
            }

            <div className="mt-2">
                <Progress percent={upload_progress} status='success' theme={{
                    success: {
                        color: '#2ecc71',
                        symbol: upload_progress + '%'
                    }
                }}/>
            </div>
        </div>
    );
}
