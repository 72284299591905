import {Redirect, Route} from "react-router-dom";
import {useAuthentication} from "hooks/AuthenticationHook";
import {PORTAL} from "routes/portal";
import PageLayout from "components/PageLayout";

const PrivateRoute = ({component: Component, ...rest}) => {
    let {isAuthenticated} = useAuthentication()

    return (
        <Route {...rest} render={props => (
            isAuthenticated() ?
                <PageLayout component={<Component {...props} />}/>
                : <Redirect to={PORTAL.LOGIN}/>
        )}/>
    );
};
export default PrivateRoute;

