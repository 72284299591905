import Loader from "components/Loader";
import {useEffect, useState, useContext} from "react";
import {CheckIcon, MailIcon, BanIcon} from "@heroicons/react/outline";
import {useAuthentication} from "hooks/AuthenticationHook";
import {ApiConsumer} from "api/ApiConsumer";
import {API_ROUTES} from "routes/api";
import {NotificationContext} from "context/NotificationContext";

export function ResultsTable() {
    const [loading, setLoading] = useState(false)
    const [results, setResults] = useState([])
    let {user} = useAuthentication()
    let {setNotification} = useContext(NotificationContext)

    function send_email(id) {
        ApiConsumer.post(API_ROUTES.BROADCAST.SCAN_EMAIL(id))
            .then(() => {
                setNotification({
                    show: true,
                    title: 'If your results are ready, you will receive them via email.',
                    type: 'success'
                })
            })
            .catch(() => {
                setNotification({
                    show: true,
                    title: 'Email Error',
                    message: 'We could not email your results, please try again later.',
                    type: 'error'
                })
            })
    }

    useEffect(() => {
        setLoading(true)
        ApiConsumer.get(`${API_ROUTES.BROADCAST.SCAN}/${user().id}`, {
            params: {user_id: user().id}, paramSerializer: function (params) {
                return JSON.stringify(params)
            }
        })
            .then(res => {
                setResults(res.data.splice(0, 5))
            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    }, [])
    return <>
        {loading ? <Loader upload_message={'loading'} full_height={true}/> :
            <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                <tr>
                    <th scope="col"
                        className="py-3.5 pl-2 pr-3 text-left text-sm font-semibold text-blue-500 sm:pl-6">
                        File Name
                    </th>
                    <th scope="col"
                        className="py-3.5 pl-2 pr-3 text-left text-sm font-semibold text-blue-500 sm:pl-6">
                        Uploaded
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Processed
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Email
                    </th>
                </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                {
                    results.map(scan => {
                        return <tr key={scan?.id}>
                            <td className="whitespace-nowrap py-4 pl-1 pr-3 text-sm font-medium text-blue-500 sm:pl-6 break-all">
                                {scan?.file?.substring(scan.file?.lastIndexOf("/") + 1, scan.file.length)}
                            </td>
                            <td className="whitespace-nowrap py-4 pl-1 pr-3 text-sm font-medium text-blue-500 sm:pl-6 break-all">
                                {new Date(scan?.created).toDateString()}
                            </td>
                            <td className="whitespace-nowrap py-4 pl-1 pr-3 text-sm font-medium sm:pl-6">
                                {scan?.processed ? <CheckIcon className='h-5 text-green-600'/> :
                                    <BanIcon className='h-5 text-red-600'/>}
                            </td>
                            <td className="whitespace-nowrap py-4 pl-1 pr-3 text-sm font-medium sm:pl-6 cursor-pointer">
                                <MailIcon className={`h-5 ${scan?.sent ? 'text-green-600' : 'text-red-600'}`}
                                          onClick={() => {
                                              send_email(scan.id)
                                          }
                                          }/>
                            </td>
                        </tr>
                    })
                }
                </tbody>
            </table>
        }
        {
            !loading ? results.length === 0 ?
                <div className={'h-screen text-center overflow-hidden overflow-y-hidden overflow-x-hidden'}>
                    No previously uploaded scans
                </div> : null : null
        }
    </>
}
