import React, {useState, useEffect} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import ReactPlayer from 'react-player'
import {
    faHeadphones,
    faMicrophoneLines,
    faMusic,
    faCompactDisc,
    faFileSignature,
    faMicrophone,
    faBuilding,
    faPerson,
    faFile,
    faFileAudio,
    faSearch,
    faCalendar,
    faLanguage,
    faPeopleCarry
} from '@fortawesome/free-solid-svg-icons'

import {
    faFileLines,
    faImage
} from '@fortawesome/free-regular-svg-icons'

import {message} from 'antd'
import {Progress} from 'react-sweet-progress'
import 'react-sweet-progress/lib/style.css'
import Loader from '../../Loader'
import $ from 'jquery'
import {ApiConsumer} from 'api/ApiConsumer'
import {API_ROUTES} from 'routes/api'
import {useHistory} from 'react-router-dom'
import {Disclosure} from '@headlessui/react'
import {ChevronUpIcon} from '@heroicons/react/solid'
import {faDeezer, faSpotify, faYoutube} from '@fortawesome/free-brands-svg-icons'
import {useAuthentication} from "hooks/AuthenticationHook";

const _validFileExtensions = ['.mp3', '.wav', '.mp4', '.mp4v']
const _validFileExtensionsArtwork = ['.jpg', '.jpeg', '.png', '.gif']

const channels = [
    {id: 0, value: '', disabled: true, selected: true, name: 'Music Type'},
    {id: 1, value: 'KTKW', disabled: false, selected: false, name: 'Kwaito Kwasa'},
    {id: 2, value: 'KS-KS', disabled: false, selected: false, name: 'Kwasa-Kwasa'},
    {id: 3, value: 'TRD', disabled: false, selected: false, name: 'Traditional'},
    {id: 4, value: 'JZ/A', disabled: false, selected: false, name: 'Jazz/Afro Pop'},
    {id: 5, value: 'CO/W', disabled: false, selected: false, name: 'Country/Western'},
    {id: 6, value: 'R&B/H', disabled: false, selected: false, name: 'R&D/Hip-Hop'},
    {id: 7, value: 'GPSL', disabled: false, selected: false, name: 'Gospel'},
    {id: 8, value: 'RGG', disabled: false, selected: false, name: 'Raggae'},
    {id: 9, value: 'HSE', disabled: false, selected: false, name: 'House'},
    {id: 10, value: 'RK', disabled: false, selected: false, name: 'Rock'},
    {id: 11, value: 'CRL', disabled: false, selected: false, name: 'Choral'},
    {id: 12, value: 'FMS', disabled: false, selected: false, name: 'Film Scores'},
    {id: 13, value: 'CSC', disabled: false, selected: false, name: 'Classic Music'},
    {id: 14, value: 'AD/JG', disabled: false, selected: false, name: 'Adverts/Jingles'},
    {id: 15, value: 'OTS', disabled: false, selected: false, name: 'Other'},
]

const genres = [
    {id: 1, value: 'amapiano', name: 'Amapiano'},
    {id: 2, value: 'gospel', name: 'Gospel'},
    {id: 3, value: 'house', name: 'House'},
    {id: 4, value: 'hip-hop', name: 'Hip Hop'},
    {id: 5, value: 'kwaito', name: 'Kwaito'},
    {id: 6, value: 'pop', name: 'Pop'}
]

export default function AddLibraryItem() {
    const [title, setTitle] = useState('')
    const [artist, setArtist] = useState('')
    const [files, setFile] = useState('')
    const [artworkFile, setArtworkFile] = useState('')
    const [album, setAlbum] = useState('')
    const [isrc, setISRC] = useState('')
    const [publisher, setPublisher] = useState('')
    const [musicType, setMusicType] = useState('')
    const [performingArtist, setPerformingArtist] = useState('')
    const [recordingCompany, setRecordingCompany] = useState('')
    const [label, setLabel] = useState('')
    const [other, setOther] = useState('')
    const [file_url, setFileUrl] = useState('')
    const [name, setName] = useState('')
    const [countries, setCountries] = useState([])
    const [country, setCountry] = useState('')
    const [laguage, setLanguage] = useState('')
    const [genre, setGenre] = useState('')
    const [author, setAuthor] = useState('')
    const [arranger, setArranger] = useState('')
    const [releaseYear, setReleaseYear] = useState('')
    const [youtube, setYouTube] = useState('')
    const [deezer, setDeezer] = useState('')
    const [spotify, setSpotify] = useState('')
    const [file_icon, setFileIcon] = useState(faFile)
    const [isLoading, setIsLoading] = useState(false)
    const [upload_message, setUploadMessage] = useState('Uploading your content...')
    const [upload_progress, setUploadProgress] = useState(0)
    const [upload_enabled, setUploadEnabled] = useState(false)

    let history = useHistory()
    let {user} = useAuthentication()

    function handleArtworkChange(e) {
        setArtworkFile(e.target.files[0].name)
    }

    function handleChange(e) {

        const name = e.target.name
        const value = e.target.value
        if (name !== 'file') {
            setName(value)
        } else {
            const value = e.target.files[0]
            setName(value)

            const file_url = URL.createObjectURL(value);
            if (!file_url.endsWith('.wma')) {
                //this.setState({borderColor: '#05ffb0'});
                setUploadEnabled(true)
                setUploadMessage('Uploading your content...')
                setFileIcon(faFileAudio)
                setFileUrl(URL.createObjectURL(value))
            } else {
                message.error(`File type not supported, please try again.`)
            }

            var getTrackName = e.target.value.split('?')
            setFile(getTrackName[getTrackName.length - 1])
        }
    }

    const handleYouTube = (e) => {
        e.preventDefault()
        if (e.target.value !== null) {
            let regex = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
            let value = regex.exec(e.target.value)[3];
            setYouTube(value)
        }
    }

    const handleSpotify = (e) => {
        e.preventDefault()
        if (e.target.value !== null) {
            let regex = /(spoti.*fy.*)\/(track\/|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
            let value = regex.exec(e.target.value)[3];
            setSpotify(value)
        }
    }

    const handleDeezer = (e) => {
        e.preventDefault()
        if (e.target.value !== null) {
            let regex = /(deez.*er.*)\/(us\/track\/|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
            let value = regex.exec(e.target.value)[3];
            setDeezer(value)
        }
    }

    function handleProgressUpdate(progress) {
        setUploadProgress(progress)
        if (progress === 100) {
            setUploadMessage('Upload is being processed. Please check your email for final results.')
        }
    }

    function handleSubmit(e) {
        e.preventDefault()
        setIsLoading(true)

        let file = $('#input-file')[0].files[0]
        let form = $('#newItemForm').get(0)
        let data = new FormData(form)
        data.append('file', file)
        data.append('user_id', user()?.id)

        const config = {
            onUploadProgress: function (progressEvent) {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                handleProgressUpdate(percentCompleted);
            }.bind(this)
        }

        ApiConsumer.post(API_ROUTES.LIBRARY.ALL, data, config)
            .then(res => {
                console.log(res)
                setIsLoading(false)
                history.push('/library')
            })
            .catch(err => {
                // console.error(err)
                setIsLoading(true);
            });
    }

    useEffect(() => {
        ApiConsumer.get(API_ROUTES.COUNTRIES.ALL)
            .then(res => {
                setCountries(res.data)
            })
            .catch(err => console.error(err))
            .finally(() => setIsLoading(false))
    }, [])

    return (
        <div className='relative'>
            <form id='newItemForm' className='min-h-full rounded px-5 py-5 mt-0 bg-white text-gray-800'
                  onSubmit={handleSubmit}>
                <div className='mt-6 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-6'>
                    <div className='mt-1 flex rounded-md shadow-sm sm:col-span-2'>
                        <span
                            className='inline-flex items-center px-3 rounded-l-md bg-gray-300 text-gray-500 sm:text-sm'>
                            <FontAwesomeIcon icon={faHeadphones} className='h-4 w-4' aria-hidden='true'/>
                        </span>
                        <input
                            id='input-title'
                            name='title'
                            className='flex-1 min-w-0 block w-full px-3 py-2 bg-gray-100 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                            type='text'
                            placeholder='Title*'
                            required={true}
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                        />
                    </div>

                    <div className='mt-1 flex rounded-md shadow-sm sm:col-span-2'>
                        <span
                            className='inline-flex items-center px-3 rounded-l-md bg-gray-300 text-gray-500 sm:text-sm'>
                            <FontAwesomeIcon icon={faMicrophoneLines} className='h-4 w-4' aria-hidden='true'/>
                        </span>
                        <input
                            id='input-artist'
                            name='artist'
                            className='flex-1 min-w-0 block w-full px-3 py-2 bg-gray-100 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                            type='text'
                            placeholder='Artist/Composer*'
                            required={true}
                            value={artist}
                            onChange={e => setArtist(e.target.value)}
                        />
                    </div>

                    <div className='sm:col-span-2 sm:row-span-5'>
                        <div className='mt-1 flex justify-center px-6 pt-5 pb-6 border-4 border-gray-300 border-dashed'>
                            <div className='space-y-1 text-center'>
                                <label>
                                    <FontAwesomeIcon icon={file_icon} size='5x' aria-hidden='true'/>
                                    <br/>
                                    <input
                                        name='file'
                                        id='input-file'
                                        type='file'
                                        accept={_validFileExtensions}
                                        required={true}
                                        value={files}
                                        onChange={handleChange}
                                        className='invisible'
                                    />
                                </label>

                            </div>
                        </div>
                        <p style={{color: 'red'}}>Please note no drag and drop, and only mp3, mp4, wav &
                            mp4v formats accepted.</p>
                        <ReactPlayer
                            url={file_url}
                            controls
                            width='100%'
                            height='50px'
                        />
                    </div>


                    <div className='mt-1 flex rounded-md shadow-sm  sm:col-span-2'>
                        <span
                            className='inline-flex items-center px-3 rounded-l-md bg-gray-300 text-gray-500 sm:text-sm'>
                            <FontAwesomeIcon icon={faMusic} className='h-4 w-4' aria-hidden='true'/>
                        </span>
                        <select
                            id='music-type'
                            name='music-type'
                            onChange={e => setMusicType(e.target.value)}
                            placeholder='Music Type'
                            required={false}
                            className='flex-1 min-w-0 block w-full px-3 py-2 bg-gray-100 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'>
                            {
                                channels.map(channel => {
                                    return <option key={channel.id} value={channel.value} disabled={channel.disabled}
                                                   defaultValue={channel.selected}>{channel.name}</option>
                                })
                            }
                        </select>
                    </div>

                    <div className='mt-1 flex rounded-md shadow-sm sm:col-span-2'>
                        <span
                            className='inline-flex items-center px-3 rounded-l-md bg-gray-300 text-gray-500 sm:text-sm'>
                            <FontAwesomeIcon icon={faCompactDisc} className='h-4 w-4' aria-hidden='true'/>
                        </span>
                        <input
                            id='input-album'
                            name='album'
                            className='flex-1 min-w-0 block w-full px-3 py-2 bg-gray-100 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                            type='text'
                            placeholder='Album'
                            required={false}
                            value={album}
                            onChange={e => setAlbum(e.target.value)}
                        />
                    </div>

                    <div className='mt-1 flex rounded-md shadow-sm sm:col-span-2'>
                        <span
                            className='inline-flex items-center px-3 rounded-l-md bg-gray-300 text-gray-500 sm:text-sm'>
                            <FontAwesomeIcon icon={faFileSignature} className='h-4 w-4' aria-hidden='true'/>
                        </span>
                        <input
                            id='input-publisher'
                            name='publisher'
                            className='flex-1 min-w-0 block w-full px-3 py-2 bg-gray-100 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                            type='text'
                            placeholder='Publisher'
                            required={false}
                            value={publisher}
                            onChange={e => setPublisher(e.target.value)}
                        />
                    </div>

                    <div className='mt-1 flex rounded-md shadow-sm sm:col-span-2'>
                        <span
                            className='inline-flex items-center px-3 rounded-l-md bg-gray-300 text-gray-500 sm:text-sm'>
                            <FontAwesomeIcon icon={faMicrophone} className='h-4 w-4' aria-hidden='true'/>
                        </span>
                        <input
                            id='input-performing-artist'
                            name='performingArtist'
                            className='flex-1 min-w-0 block w-full px-3 py-2 bg-gray-100 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                            type='text'
                            placeholder='Performing Artist'
                            value={performingArtist}
                            onChange={e => setPerformingArtist(e.target.value)}
                        />
                    </div>

                    <div className='mt-1 flex rounded-md shadow-sm sm:col-span-2'>
                        <span
                            className='inline-flex items-center px-3 rounded-l-md bg-gray-300 text-gray-500 sm:text-sm'>
                            <FontAwesomeIcon icon={faCompactDisc} className='h-4 w-4' aria-hidden='true'/>
                        </span>
                        <input
                            id='input-recording-company'
                            name='recording-company'
                            className='flex-1 min-w-0 block w-full px-3 py-2 bg-gray-100 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                            type='text'
                            placeholder='Recording Company'
                            required={false}
                            value={recordingCompany}
                            onChange={e => setRecordingCompany(e.target.value)}
                        />
                    </div>

                    <div className='mt-1 flex rounded-md shadow-sm sm:col-span-2'>
                        <span
                            className='inline-flex items-center px-3 rounded-l-md bg-gray-300 text-gray-500 sm:text-sm'>
                            <FontAwesomeIcon icon={faBuilding} className='h-4 w-4' aria-hidden='true'/>
                        </span>
                        <input
                            id='input-label'
                            name='label'
                            className='flex-1 min-w-0 block w-full px-3 py-2 bg-gray-100 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                            type='text'
                            required={true}
                            placeholder='Composer/Label*'
                            value={label}
                            onChange={e => setLabel(e.target.value)}
                        />
                    </div>
                    <div className='mt-1 flex rounded-md shadow-sm sm:col-span-2'>
                        <span
                            className='inline-flex items-center px-3 rounded-l-md bg-gray-300 text-gray-500 sm:text-sm'>
                            <FontAwesomeIcon icon={faPerson} className='h-4 w-4' aria-hidden='true'/>
                        </span>
                        <input
                            id='input-label'
                            name='author'
                            className='flex-1 min-w-0 block w-full px-3 py-2 bg-gray-100 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                            type='text'
                            placeholder='Author'
                            value={author}
                            onChange={e => setAuthor(e.target.value)}
                        />
                    </div>
                    <div className='mt-1 flex rounded-md shadow-sm sm:col-span-2'>
                        <span
                            className='inline-flex items-center px-3 rounded-l-md bg-gray-300 text-gray-500 sm:text-sm'>
                            <FontAwesomeIcon icon={faPeopleCarry} className='h-4 w-4' aria-hidden='true'/>
                        </span>
                        <input
                            id='input-label'
                            name='arranger'
                            className='flex-1 min-w-0 block w-full px-3 py-2 bg-gray-100 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                            type='text'
                            placeholder='Arranger'
                            value={arranger}
                            onChange={e => setArranger(e.target.value)}
                        />
                    </div>

                    <div className='mt-1 flex rounded-md sm:col-span-6 text-left'>
                        <div className='w-full'>
                            <div
                                className='w-full mx-auto bg-white rounded-2xl grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-6'>
                                <Disclosure>
                                    {({open}) => (
                                        <>
                                            <Disclosure.Button
                                                className='flex justify-between sm:col-span-2 px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75'>
                                                <span>Other</span>
                                                <ChevronUpIcon
                                                    className={`${open ? 'transform rotate-180' : ''
                                                    } w-5 h-5 text-blue-500`}
                                                />
                                            </Disclosure.Button>
                                            <Disclosure.Panel
                                                className='py-2 text-sm text-gray-500 sm:col-span-6 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-4 md:grid-cols-6'>
                                                <div className='relative mt-1 flex rounded-md sm:col-span-2'>
                                                    <span
                                                        className='inline-flex items-center px-3 rounded-l-md bg-gray-300 text-gray-500 sm:text-sm'>
                                                        <FontAwesomeIcon icon={faImage} className='h-4 w-4'
                                                                         aria-hidden='true'/>
                                                    </span>

                                                    <label
                                                        className='flex-1 min-w-0 block w-full px-3 py-2 bg-gray-100 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'>
                                                        {
                                                            artworkFile == '' ? 'Upload Artwork' : artworkFile
                                                        }
                                                    </label>
                                                    <div className='absolute inset-y-0 right-0 flex items-center'>
                                                        <label
                                                            class='flex flex-col items-center text-blue tracking-wide cursor-pointer bg-gray-300 text-gray-500 pr-3 h-full py-1 rounded-r-md'>
                                                            <span class='text-base leading-normal'>
                                                                <span>
                                                                    <FontAwesomeIcon icon={faSearch}
                                                                                     className='h-4 w-4 px-3'
                                                                                     aria-hidden='true'/></span>
                                                                Browse
                                                            </span>
                                                            <input type='file' id='input-artwork'
                                                                   onChange={handleArtworkChange} name='artwork'
                                                                   accept={_validFileExtensionsArtwork} class='hidden'/>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className='mt-1 flex rounded-md sm:col-span-2'>
                                                    <span
                                                        className='inline-flex items-center px-3 rounded-l-md bg-gray-300 text-gray-500 sm:text-sm'>
                                                        <FontAwesomeIcon icon={faCompactDisc} className='h-4 w-4'
                                                                         aria-hidden='true'/>
                                                    </span>
                                                    <input
                                                        id='input-isrc'
                                                        name='isrc'
                                                        className='flex-1 min-w-0 block w-full px-3 py-2 bg-gray-100 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                                        type='text'
                                                        placeholder='ISRC'
                                                        required={false}
                                                        value={isrc}
                                                        onChange={e => setISRC(e.target.value)}
                                                    />
                                                </div>

                                                <div className='mt-1 flex rounded-md sm:col-span-2'>
                                                    <span
                                                        className='inline-flex items-center px-3 rounded-l-md bg-gray-300 text-gray-500 sm:text-sm'>
                                                        <FontAwesomeIcon icon={faCalendar} className='h-4 w-4'
                                                                         aria-hidden='true'/>
                                                    </span>
                                                    <input
                                                        id='input-release-year'
                                                        name='release-year'
                                                        className='flex-1 min-w-0 block w-full px-3 py-2 bg-gray-100 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                                        type='date'
                                                        required={false}
                                                        placeholder='Release Year'
                                                        value={releaseYear}
                                                        onChange={e => setReleaseYear(e.target.value)}
                                                    />
                                                </div>

                                                <div className='mt-1 flex rounded-md sm:col-span-2'>
                                                    <span
                                                        className='inline-flex items-center px-3 rounded-l-md bg-gray-300 text-gray-500 sm:text-sm'>
                                                        <FontAwesomeIcon icon={faMusic} className='h-4 w-4'
                                                                         aria-hidden='true'/>
                                                    </span>
                                                    <select
                                                        id='genre'
                                                        name='genre'
                                                        onChange={e => setGenre(e.target.value)}
                                                        placeholder='Genre'
                                                        required={false}
                                                        className='flex-1 min-w-0 block w-full px-3 py-2 bg-gray-100 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'>
                                                        <option value='' disabled selected={true}>Genre</option>
                                                        {
                                                            genres.map(genre => {
                                                                return <option key={genre.id}
                                                                               value={genre.value}>{genre.name}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>

                                                <div className='mt-1 flex rounded-md sm:col-span-2'>
                                                    <span
                                                        className='inline-flex items-center px-3 rounded-l-md bg-gray-300 text-gray-500 sm:text-sm'>
                                                        <FontAwesomeIcon icon={faLanguage} className='h-4 w-4'
                                                                         aria-hidden='true'/>
                                                    </span>
                                                    <select
                                                        id='language'
                                                        name='language'
                                                        onChange={e => setLanguage(e.target.value)}
                                                        placeholder='Language'
                                                        required={false}
                                                        className='flex-1 min-w-0 block w-full px-3 py-2 bg-gray-100 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'>
                                                        <option value='' disabled selected={true}>Language</option>
                                                        <option value='english'>English</option>
                                                        <option value='vernac'>Vernac</option>
                                                    </select>
                                                </div>

                                                <div className='mt-1 flex rounded-md sm:col-span-2'>
                                                    <span
                                                        className='inline-flex items-center px-3 rounded-l-md bg-gray-300 text-gray-500 sm:text-sm'>
                                                        <FontAwesomeIcon icon={faFileLines} className='h-4 w-4'
                                                                         aria-hidden='true'/>
                                                    </span>
                                                    <select
                                                        id='country-produced'
                                                        name='country-produced'
                                                        onChange={e => setCountry(e.target.value)}
                                                        placeholder='Country Produced'
                                                        required={false}
                                                        className='flex-1 min-w-0 block w-full px-3 py-2 bg-gray-100 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'>
                                                        <option value='' disabled selected={true}>Country Produced
                                                        </option>
                                                        {
                                                            countries.map(country => {
                                                                return <option key={country.id}
                                                                               value={country.id}>{country.name}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>

                                                <div className='mt-1 flex rounded-md sm:col-span-2'>
                                                    <span
                                                        className='inline-flex items-center px-3 rounded-l-md bg-gray-300 text-gray-500 sm:text-sm'>
                                                        <FontAwesomeIcon icon={faYoutube} className='h-4 w-4'
                                                                         aria-hidden='true'/>
                                                    </span>
                                                    <input
                                                        id='input-youtube'
                                                        name='youtube'
                                                        className='flex-1 min-w-0 block w-full px-3 py-2 bg-gray-100 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                                        type='text'
                                                        required={false}
                                                        placeholder='YouTube page'
                                                        value={youtube}
                                                        onChange={handleYouTube}
                                                    />
                                                </div>

                                                <div className='mt-1 flex rounded-md sm:col-span-2'>
                                                    <span
                                                        className='inline-flex items-center px-3 rounded-l-md bg-gray-300 text-gray-500 sm:text-sm'>
                                                        <FontAwesomeIcon icon={faDeezer} className='h-4 w-4'
                                                                         aria-hidden='true'/>
                                                    </span>
                                                    <input
                                                        id='input-deezer'
                                                        name='deezer'
                                                        className='flex-1 min-w-0 block w-full px-3 py-2 bg-gray-100 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                                        type='text'
                                                        required={false}
                                                        placeholder='Deezer link'
                                                        value={deezer}
                                                        onChange={handleDeezer}
                                                    />
                                                </div>

                                                <div className='mt-1 flex rounded-md sm:col-span-2'>
                                                    <span
                                                        className='inline-flex items-center px-3 rounded-l-md bg-gray-300 text-gray-500 sm:text-sm'>
                                                        <FontAwesomeIcon icon={faSpotify} className='h-4 w-4'
                                                                         aria-hidden='true'/>
                                                    </span>
                                                    <input
                                                        id='input-spotify'
                                                        name='spotify'
                                                        className='flex-1 min-w-0 block w-full px-3 py-2 bg-gray-100 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                                        type='text'
                                                        required={false}
                                                        placeholder='Sportify link'
                                                        value={spotify}
                                                        onChange={handleSpotify}
                                                    />
                                                </div>
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                            </div>
                        </div>
                    </div>

                    <div className='mt-1 flex rounded-md sm:col-span-6'>
                        <Progress percent={upload_progress} status='success' theme={{
                            success: {
                                color: '#2ecc71',
                                symbol: upload_progress + '%'
                            }
                        }}/>
                    </div>

                    <div className='mt-1 flex rounded-md sm:col-span-1'>
                        <button type='submit' disabled={!upload_enabled}
                                className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500s'>
                            Submit
                        </button>
                    </div>
                </div>

                {
                    isLoading ? <Loader upload_message={upload_message}/> : ''
                }

            </form>
        </div>
    );
}
