import { BroadcastFilter } from 'components/Broadcast/filter';
import { useContext, useEffect, useState, Fragment, useRef } from 'react';
import { ApiConsumer } from 'api/ApiConsumer';
import { API_ROUTES } from 'routes/api';
import UserCard from 'components/Users/card';
import Loader from 'components/Loader';
import { PageTitleContext } from 'context/PageTitleContext'
import { CrumbsContext } from 'context/CrumbsContext'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, ExclamationIcon } from '@heroicons/react/outline'
import Select from "react-select";
import { useHistory } from "react-router-dom";

export default function Users() {
    const [users, setUsers] = useState([])
    const [details, setDetails] = useState({})
    const [loading, setLoading] = useState(true)
    let { setTitle } = useContext(PageTitleContext)
    let { setCrumbs } = useContext(CrumbsContext)
    const [open, setOpen] = useState(false)
    const [openUserType, setOpenUserType] = useState(false)
    const [editUser, setEditUser] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage] = useState(12)
    const [userTypes, setUserTypes] = useState([])
    let history = useHistory()

    const cancelButtonRef = useRef(null)
    const showModal = (e, user) => {
        e.preventDefault()
        setOpen(true)
        setEditUser(user)
    }

    const showUserTypeModal = (e, user) => {
        e.preventDefault()
        setOpenUserType(true)
        setEditUser(user)
        setEditUser((prevState) => ({
            ...prevState,
            [`user_type`]: user?.user_type?.name,
        }))
    }

    const types = [
        {
            label: 'Active',
            value: 'active'
        },
        {
            label: 'In-Active',
            value: 'inactive'
        }
    ]

    function search() {

    }

    useEffect(() => {
        setTitle('Users')
        setCrumbs([{ 'name': 'Administration', 'link': '/users' }, { 'name': 'Users', 'link': '/users' }])
        setLoading(true)
        getAllUsers()

        ApiConsumer.get(API_ROUTES.USERS.TYPES)
            .then(res => {
                let temp = []
                res.data.map(t => temp.push({ label: t.name, value: t.id }))
                setUserTypes(temp)
            })
            .catch(err => console.error(err))
    }, [])

    const getAllUsers = () => {
        ApiConsumer.get(API_ROUTES.USERS.ALL)
            .then(res => {
                setUsers(res.data)
            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    }

    const indexOfLastPost = currentPage * postsPerPage
    const indexOfFirstPost = indexOfLastPost - postsPerPage
    const currentPosts = users.slice(indexOfFirstPost, indexOfLastPost)

    const paginateFront = () => {
        if (currentPage < users.length) {
            setCurrentPage(currentPage + 1)
        }
    }
    const paginateBack = () => {
        if (currentPage * postsPerPage - 12 > 0) {
            setCurrentPage(currentPage - 1)
        }
    }

    const handleUserStatus = (status) => {
        setOpen(false)
        ApiConsumer.put(API_ROUTES.USERS.ACTIVATION(editUser?.id), { 'is_active': status })
            .then(() => getAllUsers())
            .catch(err => { });
    }

    const handleUserType = (e) => {
        e.preventDefault()
        setOpenUserType(false)
        ApiConsumer.put(API_ROUTES.USERS.UPDATE(editUser?.id), { 'user_type': !editUser?.is_active })
            .then(res => { })
            .catch(err => { });
    }

    return <>
        <div className='min-h-full px-5 py-5 mt-0'>
            <BroadcastFilter options={types} setDetails={setDetails} details={details} search={search} />
            {
                loading ? <Loader full_height={true} upload_message={'loading'} /> :
                    <div><ul role='list' className='grid grid-cols-1 gap-8 sm:grid-cols-2 xl:grid-cols-4 mt-5'>
                        {currentPosts.map(user => {
                            return <UserCard user={user} showModal={showModal} showUserTypeModal={showUserTypeModal} />
                        })}
                    </ul>

                        <div className='py-2'>
                            <div>
                                <p className='text-sm text-gray-700'>
                                    Showing&nbsp;
                                    <span className='font-medium'> {currentPage * postsPerPage - 12} </span>
                                    to&nbsp;
                                    <span className='font-medium'>
                                        {
                                            currentPage * postsPerPage > users.length ? users.length : currentPage * postsPerPage
                                        }
                                    </span>
                                    &nbsp;of&nbsp;
                                    <span className='font-medium'> {users.length} </span>
                                    results
                                </p>
                            </div>
                            <nav className='block'></nav>
                            <div>
                                <nav className='relative z-0 inline-flex rounded-md shadow-sm -space-x-px'
                                    aria-label='Pagination'>
                                    {
                                        (currentPage * postsPerPage) - 10 <= 0 ? <a disabled onClick={() => {
                                            paginateBack();
                                        }} className='relative inline-flex items-center cursor-pointer px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'
                                        ><span>Previous</span></a> : <a onClick={() => {
                                            paginateBack();
                                        }} href='#'
                                            className='relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'
                                        ><span>Previous</span></a>
                                    }

                                    {
                                        (currentPage * postsPerPage) > users.length ?
                                            <a disabled className='relative inline-flex cursor-pointer items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'
                                            ><span>Next</span></a> : <a onClick={() => {
                                                paginateFront();
                                            }} href='#'
                                                className='relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'
                                            ><span>Next</span></a>
                                    }
                                </nav>
                            </div>
                        </div>
                    </div>
            }
        </div>

        <Transition.Root show={open} as={Fragment}>
            <Dialog as='div' className='fixed z-10 inset-0 overflow-y-auto' initialFocus={cancelButtonRef}
                onClose={setOpen}>
                <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
                        {
                            editUser?.is_active ? <div
                                className='relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
                                <div className='sm:flex sm:items-start'>
                                    <div
                                        className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
                                        <ExclamationIcon className='h-6 w-6 text-red-600' aria-hidden='true' />
                                    </div>
                                    <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                                        <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
                                            {editUser?.first_name} {editUser?.last_name}
                                        </Dialog.Title>
                                        <div className='mt-2 w-full'>
                                            <p className='text-sm text-gray-500'>
                                                Are you sure you want to deactivate this user account?
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense'>
                                    <button type='button'
                                        className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-0 sm:col-start-2 sm:text-sm'
                                        onClick={event => {
                                            event.preventDefault()
                                            handleUserStatus(false)
                                        }}>
                                        Deactivate
                                    </button>

                                    <button type='button'
                                        className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-0 sm:mt-0 sm:col-start-1 sm:text-sm'
                                        onClick={() => setOpen(false)} ref={cancelButtonRef}>
                                        Cancel
                                    </button>
                                </div>
                            </div> : <div
                                className='relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
                                <div className='sm:flex sm:items-start'>
                                    <div
                                        className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10'>
                                        <CheckIcon className='h-6 w-6 text-green-600' aria-hidden='true' />
                                    </div>

                                    <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                                        <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
                                            {editUser?.first_name} {editUser?.last_name}
                                        </Dialog.Title>
                                        <div className='mt-2 w-full'>
                                            <p className='text-sm text-gray-500'>
                                                Are you sure you want to activate this user account?
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense'>
                                    <button type='button'
                                        className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-0 sm:col-start-2 sm:text-sm'
                                        onClick={event => {
                                            event.preventDefault()
                                            handleUserStatus(true)
                                        }}>
                                        Activate
                                    </button>

                                    <button type='button'
                                        className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-0 sm:mt-0 sm:col-start-1 sm:text-sm'
                                        onClick={() => setOpen(false)} ref={cancelButtonRef}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        }
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>

        <Transition.Root show={openUserType} as={Fragment}>
            <Dialog as='div' className='fixed z-10 inset-0 overflow-y-auto' initialFocus={cancelButtonRef}
                onClose={setOpenUserType}>
                <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
                        <div
                            className='relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
                            <div className='sm:flex sm:items-start'>
                                <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                                    <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
                                        {editUser?.first_name} {editUser?.last_name}
                                    </Dialog.Title>
                                    <div className='mt-2 w-full'>
                                        <div className='sm:col-span-2'>
                                            <div className='max-w-lg'>
                                                <p className='text-sm text-gray-500'>Change the user type of the user.</p>
                                                <div className='mt-4 space-y-4'>
                                                    <Select
                                                        id="search_type"
                                                        name="search_type"
                                                        placeholder="No Types"
                                                        isMulti
                                                        options={userTypes}
                                                        classNamePrefix="multi-select"
                                                        className={`multi-select text-xs w-full`}
                                                        onChange={(event) => {
                                                            setDetails((prevState) => ({
                                                                ...prevState,
                                                                [`user_type`]: [...event].map(
                                                                    (option) => option.value,
                                                                ),
                                                            }))
                                                        }}
                                                        value={
                                                            Object.keys(userTypes).length !== 0
                                                                ? details?.user_type?.map((selectedOption) => {
                                                                    return userTypes[
                                                                        userTypes.findIndex(
                                                                            (option) => option.value === selectedOption,
                                                                        )
                                                                    ]
                                                                })
                                                                : editUser?.user_type?.id
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense'>
                                <button type='button'
                                    className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-0 sm:col-start-2 sm:text-sm'
                                    onClick={handleUserType}>
                                    Submit
                                </button>

                                <button type='button'
                                    className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-0 sm:mt-0 sm:col-start-1 sm:text-sm'
                                    onClick={() => setOpenUserType(false)} ref={cancelButtonRef}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    </>
}
