const API_VERSION = "v1"
const API_BASE_URL = "https://cosbots-api.imonitor.live/api"
const API_URL = `${API_BASE_URL}/${API_VERSION}`

export const API_ROUTES = {
    AUTHENTICATION: {
        LOGIN: `${API_URL}/users/login`,
        PASSWORD_TOKEN: `${API_URL}/users/password/token`,
        FORGOT_PASSWORD: `${API_URL}/users/password/reset`,
    },
    BROADCAST: {
        CHANNELS: `${API_URL}/broadcast/streams`,
        SCAN: `${API_URL}/broadcast/scan`,
        SCAN_EMAIL: function (stream_id) {
            return`${API_URL}/broadcast/scan/email/${stream_id}`
        },
        CHANNEL: function (stream_id) {
            return `${API_URL}/broadcast/streams/${stream_id}`
        },
        MONITORING: function (broadcast_id) {
            return `${API_URL}/broadcast/monitoring/${broadcast_id}`
        },
        CHARTS: function (broadcast_id) {
            return `${API_URL}/broadcast/charts/${broadcast_id}`
        },
        REPORTS: {
            BROADCAST: `${API_URL}/broadcast/reports/broadcast`,
            BROADCAST_DOWNLOAD: `${API_URL}/broadcast/reports/broadcast/download`,
            CHARTS: `${API_URL}/broadcast/reports/charts`,
            CHARTS_DOWNLOAD: `${API_URL}/broadcast/reports/charts/download`,
            BY_TYPE: function (type) {
                return `${API_URL}/broadcast/charts/type/${type}`
            },
            ALL: `${API_URL}/broadcast/charts/top/all`,
            TOP_ARTISTS: `${API_URL}/broadcast/charts/top/artists`,
            TOP_ARTISTS_BY_BROADCAST: function (broadcast_id) {
                return `${API_URL}/broadcast/charts/top/artists/${broadcast_id}`
            },
            TOP_LABELS: `${API_URL}/broadcast/charts/top/labels`,
            TOP_LABELS_BY_BROADCAST: function (broadcast_id) {
                return `${API_URL}/broadcast/charts/top/labels/${broadcast_id}`
            },
            COMPARISON: {
                ALL: `${API_URL}/broadcast/reports/comparison`,
                ALL_BY_STATION: function (station_id){
                    return `${API_URL}/broadcast/reports/comparison/${station_id}`
                },
                BY_MONTH: `${API_URL}/broadcast/reports/monthly/comparison`,
                BY_MONTH_PER_STATION: function (station_id) {
                    return `${API_URL}/broadcast/reports/monthly/comparison/${station_id}`
                },
                LOCATION: `${API_URL}/broadcast/reports/location`,
                LOCATION_BY_STATION: function (station_id) {
                    return `${API_URL}/broadcast/reports/location/${station_id}`
                },
                STATION: `${API_URL}/broadcast/reports/station/comparison`,
                STATION_MONTHLY: `${API_URL}/broadcast/reports/station/month/comparison`,
                CATALOGUE: `${API_URL}/broadcast/reports/catalogue`,
                CATALOGUE_DOWNLOAD: `${API_URL}/broadcast/reports/catalogue/download`
            },
            GRAPHICAL: `${API_URL}/broadcast/reports/graphical`,
        }
    },
    USERS: {
        ALL: `${API_URL}/users`,
        REGISTER: `${API_URL}/users/register`,
        TYPES: `${API_URL}/users/types`,
        SINGLE: function (user_id) {
            return `${API_URL}/users/detail/${user_id}`
        },
        UPDATE: function (user_id) {
            return `${API_URL}/users/detail/${user_id}`
        },
        ACTIVATION: function (user_id) {
            return `${API_URL}/users/activation/${user_id}`
        }
    },
    COUNTRIES: {
        ALL: `${API_URL}/content/country`
    },
    ANALYTICS: {
        BYLABEL: `${API_URL}/TrackedByLabel/`,
        TRACKEDPERCENTAGE: `${API_URL}/TrackedPercentage/`
    },
    LIBRARY: {
        ALL: `${API_URL}/library/`,
        STATS: `${API_URL}/DigiLib/Stats/`,
        LIBRARYCOUNT: `${API_URL}/DigiLib/LibraryCount/`,
        BULK: `${API_URL}/library/bulk`,

        BY_TYPE: function (bucket_id) {
            return `${API_URL}/DigiLib/api/v1/Library/${bucket_id}`
        },
        MEDIAFILE: `${API_URL}/DigiLib/MediaFile/?key=`,
        DATATABLE: `${API_URL}/DigiLib/MediaFile/?id=`
    },
    ORGANISATIONS: {
        ALL: `${API_URL}/content/organisation`
    },
    CONTENT: {
        STATS: `${API_URL}/content/stats`
    }
}
