import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { PlayIcon } from "@heroicons/react/outline";
import { MusicSlideOverContext } from "context/MusicSlideOverContext";
import { useContext } from "react";
import Loader from "components/Loader";
import icon from "assets/images/music-icon.svg";
import { MusicPlayerContext } from "context/MusicPlayer";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export function BroadcastTable({ loading = true, data = [], currentPage, setPage, total, query = false }) {
    let { music, setMusic } = useContext(MusicSlideOverContext)
    const { setPlaying } = useContext(MusicPlayerContext);

    const pages = [];
    const numPages = Math.ceil(total / 15);

    for (let i = 1; i <= numPages; i++) {
        if (i <= 5 || i == numPages || Math.abs(currentPage - i) <= 1)
            pages.push(i);
    }

    return <>
        {loading ? <Loader upload_message={'loading'} full_height={true} /> :
            <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className="relative w-8 px-6 sm:w-16 sm:px-8" >
                            <PlayIcon className={'w-6 text-center align-center text-blue-500'} />
                        </th>
                        <th scope="col"
                            className="py-3.5 pl-2 pr-3 text-left text-sm font-semibold text-blue-500 sm:pl-6">
                            Title
                        </th>
                        {
                            !query ? <>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Artist/Composer
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Album
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Label
                                </th>
                            </> : null
                        }
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Duration
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Timestamp
                        </th>
                        {
                            !query ? <>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    ISRC
                                </th>
                            </> : null
                        }
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                    {
                        data.map(track => (
                            <tr key={track?.id} className={'cursor-pointer'}>
                                <td onClick={() => {
                                    let music = {
                                        music: {
                                            id: track.id,
                                            image: { icon },
                                            title: track?.title,
                                            artist: track?.artist,
                                            file: track?.recording?.recording
                                        }
                                    }
                                    setPlaying(music)
                                }}
                                    className={classNames(
                                        'whitespace-nowrap py-4 pr-3 text-sm font-medium pl-6',
                                        music?.id === track?.id ? 'text-blue-400' : 'text-gray-900'
                                    )}
                                >
                                    <PlayIcon className={'w-6 text-center align-center text-green-500'} />
                                </td>
                                <td className="py-4 pl-1 pr-3 text-sm font-medium text-blue-500 sm:pl-6 flex-wrap"
                                    onClick={() => {
                                        setMusic({ ...track, ...{ open: true } })
                                    }}>
                                    {track?.title}
                                </td>
                                {
                                    !query ? <>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 max-w-10">{track?.artist?.slice(0, 20)}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{track?.album?.slice(0, 20)}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{track?.label?.slice(0, 20)}</td>
                                    </> : null
                                }
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{track?.duration}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{track?.timestamp?.replace('T', ' ')}</td>
                                {
                                    !query ? <>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{track?.isrc}</td>
                                    </> : null
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        }
        {
            !loading ? data.length === 0 ?
                <div className={'h-screen text-center overflow-hidden overflow-y-hidden overflow-x-hidden'}>
                    No results
                </div> : null : null
        }
        <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
            <div className="flex-1 flex justify-between sm:hidden">
                <a
                    href="#"
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                    Previous
                </a>
                <a
                    href="#"
                    className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                    Next
                </a>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                    <p className="text-sm text-gray-700">
                        Showing <span className="font-medium">{currentPage * 15 - 15}</span> to <span
                            className="font-medium">{(currentPage * 15) > total ? total : currentPage * 15}</span> of&nbsp;
                        <span className="font-medium">{total}</span> results
                    </p>
                </div>
                <div>
                    <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                        <a
                            href="#"
                            className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                        >
                            <span className="sr-only">Previous</span>
                            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                        </a>
                        {
                            pages.map(page => {
                                return <button
                                    key={page}
                                    aria-current="page"
                                    onClick={() => {
                                        setPage(page)
                                    }}
                                    className={`z-10 ${currentPage === page ? "border-2 bg-indigo-50 border-indigo-500 text-indigo-600" : "border-gray-300 text-gray-500 hover:bg-gray-200"} relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                                >
                                    {page}
                                </button>
                            })
                        }
                        <a
                            href="#"
                            className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                        >
                            <span className="sr-only">Next</span>
                            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                        </a>
                    </nav>
                </div>
            </div>
        </div>
    </>
}
