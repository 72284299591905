import Select from 'react-select'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {useEffect, useState} from "react";
import {sub} from "date-fns";
import {ApiConsumer} from "api/ApiConsumer";
import {API_ROUTES} from "routes/api";

const type = [
    {
        label: 'Recognised',
        value: 'recognised'
    },
    {
        label: 'Unrecognised',
        value: 'unrecognised'
    }
]

const catalogue = [
    {
        label: 'All',
        value: 'All'
    },
    {
        label: 'National',
        value: 'National'
    },
    {
        label: 'International',
        value: 'International'
    }
]

const filter = [
    {
        label: 'Title',
        value: 'none'
    },
    {
        label: 'By Artist',
        value: 'artist'
    },
    {
        label: 'By Label',
        value: 'label'
    }
]

const chart_type = [
    {
        label: 'Full list',
        value: 10000000
    },
    {
        label: 'Top 10',
        value: 10
    },
    {
        label: 'Top 20',
        value: 20
    },
    {
        label: 'Top 50',
        value: 50
    },
    {
        label: 'Top 100',
        value: 100
    }
]

const limit_type = [
    {
        label: 'Full list',
        value: 100000000
    },
    {
        label: '10',
        value: 10
    },
    {
        label: '20',
        value: 20
    },
    {
        label: '50',
        value: 50
    },
    {
        label: '100',
        value: 100
    }
]

export default function ChartFilter({
                                        details,
                                        setDetails,
                                        title = 'Chart Type',
                                        limit = false,
                                        additional_filters = false
                                    }) {
    const [channels, setChannels] = useState([])
    const [list, setList] = useState([])

    const dateChange = (dates) => {
        const [start, end] = dates

        setDetails((prevState) => ({
            ...prevState,
            [`start_date`]: start,
        }))
        setDetails((prevState) => ({
            ...prevState,
            [`end_date`]: end,
        }))
    }

    useEffect(() => {
        setDetails((prevState) => ({
            ...prevState,
            [`start_date`]: sub(new Date(), {months: 1}),
        }))

        setDetails((prevState) => ({
            ...prevState,
            [`end_date`]: new Date(),
        }))

        ApiConsumer.get(API_ROUTES.BROADCAST.CHANNELS)
            .then(res => {
                let temp = []
                res.data.map(item => temp.push({value: item.id, label: item.name}))
                setChannels(temp)
            })
            .catch(err => console.error(err))

        limit ? setList(limit_type) : setList(chart_type)
    }, [])

    return <>
        <div className="box">
            <div className="box-wrapper ">
                <h3 className='mb-2 font-semibold profile-overview-title'>Filters</h3>
                <div className="bg-white rounded p-3 shadow-lg">
                    <div className="border-b border-gray-200 grid grid-cols-12 space-x-4 pb-3">
                        <div className={`${additional_filters ? 'col-span-2' : 'col-span-2'} w-full`}>
                            <label className="text-sm" htmlFor="stations">
                                {title}:
                            </label>
                            <Select
                                id="chart_type"
                                name="chart_type"
                                placeholder={limit ? 'Full Playlist' : 'Full list'}
                                isMulti={false}
                                options={list}
                                classNamePrefix="multi-select"
                                className={`multi-select text-xs w-full`}
                                onChange={(event) => {
                                    setDetails((prevState) => ({
                                        ...prevState,
                                        [`chart_type`]: event.value
                                    }))
                                }}
                                defaultValue={100000000}
                                value={list[
                                    list.findIndex(
                                        (option) => option.value === details?.chart_type,
                                    )
                                    ]}
                            />
                        </div>
                        {
                            additional_filters ?
                                <div className={`${additional_filters ? 'col-span-2' : 'col-span-2'}`}>
                                    <label className="text-sm" htmlFor="stations">
                                        Chart Order:
                                    </label>
                                    <Select
                                        id="filter"
                                        name="filter"
                                        placeholder={'None'}
                                        isMulti={false}
                                        options={filter}
                                        classNamePrefix="multi-select"
                                        className={`multi-select text-xs w-full`}
                                        onChange={(event) => {
                                            setDetails((prevState) => ({
                                                ...prevState,
                                                [`chart_order`]: event.value
                                            }))
                                        }}
                                        value={filter[
                                            filter.findIndex(
                                                (option) => option.value === details?.chart_order,
                                            )
                                            ]}
                                    />
                                </div> : null
                        }

                        <div className={`${additional_filters ? 'col-span-2' : 'col-span-3'}`}>
                            <label className="text-sm" htmlFor="stations">
                                Stations:
                            </label>
                            <Select
                                id="stations"
                                name="stations"
                                placeholder="All stations"
                                isMulti
                                options={channels}
                                classNamePrefix="multi-select"
                                className={`multi-select text-xs w-full`}
                                onChange={(event) => {
                                    setDetails((prevState) => ({
                                        ...prevState,
                                        [`stations`]: [...event].map(
                                            (option) => option.value,
                                        ),
                                    }))
                                }}
                                defaultValue={0}
                                value={
                                    Object.keys(channels).length !== 0
                                        ? details?.stations?.map((selectedOption) => {
                                            return channels[
                                                channels.findIndex(
                                                    (option) => option.value === selectedOption,
                                                )
                                                ]
                                        })
                                        : 0
                                }
                            />
                        </div>

                        <div className={`${additional_filters ? 'col-span-2' : 'col-span-2'}`}>
                            <label className="text-sm" htmlFor="mission_types">
                                Recognition Type:
                            </label>
                            <Select
                                id="mission_types"
                                name="mission_types"
                                placeholder="Recognised & Unrecognised"
                                isMulti
                                options={type}
                                isDisabled={details?.chart_order !== 'none'}
                                classNamePrefix="multi-select"
                                className={`multi-select text-xs w-full`}
                                onChange={(event) => {
                                    setDetails((prevState) => ({
                                        ...prevState,
                                        [`type`]: [...event].map(
                                            (option) => option.value,
                                        ),
                                    }))
                                }}
                                defaultValue={0}
                                value={
                                    Object.keys(type).length !== 0
                                        ? details?.type?.map((selectedOption) => {
                                            return type[
                                                type.findIndex(
                                                    (option) => option.value === selectedOption,
                                                )
                                                ]
                                        })
                                        : 0
                                }
                            />
                        </div>

                        <div className={`${additional_filters ? 'col-span-2' : 'col-span-2'}`}>
                            <label className="text-sm" htmlFor="mission_types">
                                Catalogue Type:
                            </label>
                            <Select
                                id="mission_types"
                                name="mission_types"
                                placeholder="National & International"
                                isMulti={false}
                                options={catalogue}
                                // isDisabled={details?.catalogue !== 'none'}
                                classNamePrefix="multi-select"
                                className={`multi-select text-xs w-full`}
                                onChange={(event) => {
                                    setDetails((prevState) => ({
                                        ...prevState,
                                        [`catalogue`]: event.value
                                    }))
                                }}
                                defaultValue={0}
                                value={catalogue[
                                    catalogue.findIndex(
                                        (option) => option.value === details?.catalogue,
                                    )
                                    ]}
                            />
                        </div>

                        <div className={`${additional_filters ? 'col-span-2' : 'col-span-3'}`}>
                            <label className="text-sm" htmlFor="date_range">
                                Date Range:
                            </label>
                            <DatePicker
                                onKeyDown={(e) => {
                                    e.preventDefault()
                                }}
                                dateFormat="dd MMMM yyyy"
                                selected={details?.start_date}
                                onChange={(event) => dateChange(event)}
                                className="form-field w-full border rounded p-1.5 border-gray-300"
                                startDate={details?.start_date}
                                endDate={details?.end_date}
                                selectsRange
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
