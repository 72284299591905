import {BrowserRouter as Router, Route, Switch, useHistory} from "react-router-dom";
import {useState} from "react";
import {AuthenticationContext} from "context/AuthenticationContext";
import PrivateRoute from "components/PrivateRoute";
import APP_ROUTES, {ADMIN_ROUTES, BROADCAST_ROUTES, LIBRARY_ROUTES, REPORT_ROUTES} from "routes/app";
import {MusicSlideOverContext} from "context/MusicSlideOverContext";
import {PageTitleContext} from "context/PageTitleContext";
import {MusicPlayerContext, PlayerProperties} from "context/MusicPlayer";
import {CrumbsContext} from "context/CrumbsContext";
import PageView from "components/PageView";
import Notification from "components/Notification/notification";
import {NotificationContext} from "context/NotificationContext";

export default function App() {
    const [user, setUser] = useState({});
    const [music, setMusic] = useState({});
    const [title, setTitle] = useState('')
    const [crumbs, setCrumbs] = useState([])
    const [notification, setNotification] = useState({});
    const [playing, setPlaying] = useState(PlayerProperties)


    let history = useHistory();
    return <>
        <Router history={history}>
            <AuthenticationContext.Provider value={{user, setUser}}>
                <MusicPlayerContext.Provider value={{playing, setPlaying}}>
                    <MusicSlideOverContext.Provider value={{music, setMusic}}>
                        <CrumbsContext.Provider value={{crumbs, setCrumbs}}>
                            <PageTitleContext.Provider value={{title, setTitle}}>
                                <NotificationContext.Provider value={{notification, setNotification}}>
                                    <Notification/>
                                    <PageView/>
                                    <Switch>
                                        {
                                            APP_ROUTES.concat(BROADCAST_ROUTES).concat(LIBRARY_ROUTES).concat(REPORT_ROUTES).concat(ADMIN_ROUTES).map((route, index) => {
                                                return route.private ?
                                                    <PrivateRoute key={index} exact={route.exact} path={route.link}
                                                                  component={route.page}/> :
                                                    <Route exact={route.exact} path={route.link}
                                                           component={route.page}/>
                                            })
                                        }
                                    </Switch>
                                </NotificationContext.Provider>
                            </PageTitleContext.Provider>
                        </CrumbsContext.Provider>
                    </MusicSlideOverContext.Provider>
                </MusicPlayerContext.Provider>
            </AuthenticationContext.Provider>
        </Router>
    </>
}
