import { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { ApiConsumer } from "api/ApiConsumer";
import { useUtils } from "hooks/UtilityHook";

export function Line({ url }) {
  const [data, setData] = useState([]);
  const { getMonth } = useUtils();

  const options = {
    title: "",
    legend: { position: "bottom" },
  };

  useEffect(() => {
    ApiConsumer.get(url)
      .then((res) => {
        const channelNames = res.data.reduce((nameSet, val) => {
          const name = val.broadcast_stream__name;
          if (!name) return nameSet;

          nameSet.add(name);
          return nameSet;
        }, new Set());

        const tableLen = 13; // first row is the heading, the rest are the 12 months of the year
        const ret = Array(tableLen);
        let allocated_index = 1; // 1 because the month numbers start from 1
        const indexes = {}; // keep an index of each channel so we can quickly update its column

        const headingIndex = 0;
        ret[headingIndex] = ["Month", ...Array.from(channelNames)];
        res.data.forEach((item) => {
          const name = item.broadcast_stream__name;

          // if there is no name, we skip for now.. TODO: change api so that this is not possible
          if (!name) return;

          const monthNum = item.month;

          ret[monthNum] = ret[monthNum] || [
            getMonth(monthNum),
            ...Array(channelNames.size).fill(0),
          ];

          indexes[name] = indexes[name] || allocated_index++;

          const i = indexes[name];

          ret[headingIndex][i] = ret[headingIndex][i] || name;

          ret[monthNum][i] = item.recognised;
        });

        setData(ret);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {});
  }, [url]);

  return (
    <>
      <Chart
        className="rounded shadow-lg"
        chartType="LineChart"
        width="100%"
        height="400px"
        data={data}
        options={options}
      />
    </>
  );
}
