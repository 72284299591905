import Select from "react-select";
import {SearchIcon} from "@heroicons/react/outline";

export function BroadcastFilter({search, setDetails = {}, details = {}, options = []}) {
    return <div className="box">
        <div className="box-wrapper">
            <div className=" bg-white rounded flex items-center w-full p-3 shadow-sm border border-gray-200">
                <input type="search" name="" id="" placeholder="Type search term here..." xModel="q"
                       className="w-full pl-4 text-sm outline-none focus:outline-none bg-transparent"
                       onChange={event => {
                           setDetails((prevState) => ({
                               ...prevState,
                               [`term`]: event.target.value
                           }))
                       }}
                />
                {
                    options.length !== 0 ?
                        <div className="select mr-3">
                            <Select
                                id="search_type"
                                name="search_type"
                                placeholder="No Types"
                                isMulti
                                options={options}
                                classNamePrefix="multi-select"
                                className={`multi-select text-xs w-full`}
                                onChange={(event) => {
                                    setDetails((prevState) => ({
                                        ...prevState,
                                        [`term_types`]: [...event].map(
                                            (option) => option.value,
                                        ),
                                    }))
                                }}
                                defaultValue={0}
                                value={
                                    Object.keys(options).length !== 0
                                        ? details?.search_types?.map((selectedOption) => {
                                            return options[
                                                options.findIndex(
                                                    (option) => option.value === selectedOption,
                                                )
                                                ]
                                        })
                                        : 0
                                }
                            />
                        </div>
                        : null
                }
                <SearchIcon className="h-8 w-8 pt-1.5 cursor-pointer hover:text-green-500"
                            aria-hidden="true"
                            onClick={()=>{
                                search()
                            }}/>
            </div>
        </div>
    </div>
}
