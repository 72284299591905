import {useContext, useEffect, useState} from "react";
import {PageTitleContext} from "context/PageTitleContext";
import BroadcastFilter from "components/Reports/BroadcastFilter";
import {SearchIcon, DocumentDownloadIcon, DocumentReportIcon} from "@heroicons/react/outline";
import {BroadcastTable} from "components/Broadcast/table";
import {ApiConsumer} from "api/ApiConsumer";
import {API_ROUTES} from "routes/api";
import {CrumbsContext} from "context/CrumbsContext";

export default function Reports() {
    const [details, setDetails] = useState({})
    const [loading, setLoading] = useState(false)
    const [results, setResults] = useState([])
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1)
    let {setTitle} = useContext(PageTitleContext)
    let {setCrumbs} = useContext(CrumbsContext)

    useEffect(() => {
        setTitle('Broadcast Reports')
        setCrumbs([{'name': 'Charts & Reports', 'link': '/reports'}, {
            'name': 'Broadcast Reports',
            'link': '/reports/broadcast'
        }])
    }, [])


    function searchReport() {
        setLoading(true)
        ApiConsumer.get(API_ROUTES.BROADCAST.REPORTS.BROADCAST, {
            params: {...details, page: page}, paramSerializer: function (params) {
                return JSON.stringify(params)
            }
        })
            .then(res => {
                setResults(res.data.data)
                setTotal(res.data.count)
            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    }

    function downloadReport() {
        setLoading(true)
        ApiConsumer.get(API_ROUTES.BROADCAST.REPORTS.BROADCAST_DOWNLOAD, {
            params: {...details, page: page}, paramSerializer: function (params) {
                return JSON.stringify(params)
            }, responseType: 'blob'
        })
            .then(({data}) => downloadExcelDocument('Broadcast Report.xlsx', data))
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    }

    const downloadExcelDocument = (name, data) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    useEffect(() => {
        searchReport()
    }, [page])

    return <>
        <div className="min-h-full px-5 py-5 mt-0">
            <BroadcastFilter details={details} setDetails={setDetails}/>
            <h3 className='mb-2 font-semibold profile-overview-title mt-5 flex mx-full'>
                <span className="min-w-0 flex-1 items-center">
                Results ({total})
              </span>
                <SearchIcon className="h-8 w-8 pt-1.5 cursor-pointer hover:text-green-500" aria-hidden="true"
                            onClick={searchReport}/>
                <DocumentDownloadIcon className="h-8 w-8 pt-1.5 cursor-pointer hover:text-green-500" aria-hidden="true"
                                      onClick={downloadReport}/>
                {/*<DocumentReportIcon className="h-8 w-8 pt-1.5 cursor-pointer hover:text-green-500" aria-hidden="true"/>*/}
            </h3>
            <BroadcastTable data={results} loading={loading} currentPage={page} setPage={setPage} total={total}/>
        </div>
    </>
}
