import {useContext, useEffect, useRef, useState} from "react";
import WaveSurfer from "wavesurfer.js";
import {useWaveform} from "hooks/WaveformHook";
import {MusicPlayerContext} from "context/MusicPlayer";
import {PlayIcon, StopIcon} from "@heroicons/react/outline";
import icon from 'assets/images/music-icon.svg'

export default function Footer() {
    const {playing} = useContext(MusicPlayerContext);
    const [on, setOn] = useState(false);
    const [volume, setVolume] = useState(0.5);

    const waveformRef = useRef(null);
    const wavesurfer = useRef(null);

    const {buildWaveformOptions} = useWaveform();

    useEffect(() => {
        if (playing?.music?.file != null) {
            const formWaveSurferOptions = buildWaveformOptions(waveformRef.current)

            wavesurfer.current = WaveSurfer?.create(formWaveSurferOptions);

            wavesurfer.current?.load(`${playing.music.file.includes('https://') ? '' : 'https://'}${playing.music.file}`);

            wavesurfer.current?.on("ready", function () {
                if (wavesurfer.current) {
                    wavesurfer.current?.setVolume(volume);
                    setVolume(volume);
                    setOn(false)
                }
            });

            return () => wavesurfer.current?.destroy();
        }
    }, [playing]);

    useEffect(() => {
        wavesurfer.current?.setVolume(volume);
    }, [volume])
    return <>
        <footer className="bg-white sticky bottom-0 bg-gray-900">
            <div className="mx-auto h-20 pt-4">
                <div className="grid grid-cols-12 gap-4">
                    <div className='col-span-2 text-white pl-5'>
                        <div className="grid grid-cols-4 gap-4">
                            <div className='pt-1.5'>
                                <img className="w-full md:w-72 block rounded"
                                     src={icon} alt=""/>
                            </div>
                            <div className='col-span-3 pt-1.5'>
                                {playing?.music?.title}<br/>{playing?.music?.artist}
                            </div>
                        </div>
                    </div>
                    <div ref={waveformRef} className={'w-full col-span-8'}/>
                    <div className='col-span-2 pt-1.5'>
                        <div className='w-1/2'>
                            {
                                playing?.music?.file !== undefined ?
                                    on ? <StopIcon className={'h-10 text-red-500'} onClick={() => {
                                        if (waveformRef?.current !== null) {
                                            wavesurfer?.current?.stop()
                                            setOn(false);
                                        }
                                    }}/> : <PlayIcon className={'h-10 text-green-500'} onClick={() => {
                                        if (waveformRef?.current !== null) {
                                            wavesurfer?.current?.play()
                                            setOn(true);
                                        }
                                    }}/>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </>
}
