import React, { useState } from "react"
import { Upload, message } from 'antd'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload, faInbox } from "@fortawesome/free-solid-svg-icons"
import { Progress } from 'react-sweet-progress'
import "react-sweet-progress/lib/style.css"
import FileUpload from "components/FileUpload"
import $ from "jquery";
import {ApiConsumer} from "api/ApiConsumer";
import {API_ROUTES} from "routes/api";
import {useAuthentication} from "hooks/AuthenticationHook";

const { Dragger } = Upload;

export default function BulkUpload() {
    const [upload_progress, setUploadProgress] = useState(0)
    let {user} = useAuthentication()

    function handleOnChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            let data = new FormData()
            if(info.file != null)
                data.append('file', info.file)
            data.append('user_id', user()?.id)

            ApiConsumer.post(API_ROUTES.LIBRARY.BULK, data)
                .then(res => {
                    console.log(res)
                })
                .catch(err => {
                    console.error(err)
                });

        } else if (status === 'error') {
            //message.error(`${info.file.name} file upload failed.`);
        }
    }

    return (
        <div className="min-h-full rounded px-5 py-5 mt-0 bg-white text-gray-800">
            <div className='mt-2 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-4'>
                <div className="mt-1 flex rounded-md sm:col-span-1 sm:row-span-2 ">
                    <div className="grid gap-y-4 gap-x-4 sm:grid-cols-2 sm:grid-rows-3">
                        <div className="sm:row-span-1 sm:col-span-2">
                            <p>Click or drag file on the drag area on the right to upload.</p>
                        </div>

                        <div className="sm:row-span-1  sm:col-span-2">
                            Please download the specified template and zip into a folder with all the audio files.
                        </div>

                        <div className="sm:row-span-1">
                            <a
                                href={'/Metadata.xlsx'}
                                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer"
                            >
                                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                    <FontAwesomeIcon icon={faDownload} className="h-4 w-4" aria-hidden="true" />
                                </span>
                                Template
                            </a>
                        </div>
                    </div>
                </div>

                <div className="mt-1 flex rounded-md sm:col-span-3 justify-center items-center text-center bg-white">
                    <FileUpload upload_progress={upload_progress} icon={faInbox}  icon_size='3x'/>
                </div>

            </div>
        </div>
    );
}
