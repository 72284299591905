import {useContext, useEffect, useState} from "react";
import {PageTitleContext} from "context/PageTitleContext";
import MusicCard from "components/Music/card";
import {ApiConsumer} from "api/ApiConsumer";
import {API_ROUTES} from "routes/api";
import Loader from "components/Loader";
import {CrumbsContext} from "context/CrumbsContext";
import {Bar} from "components/Charts/bar";
import {StackedLine} from "components/Charts/stacked-line";
import {Doughnut} from "components/Charts/doughnut";
import {StatsBar} from "components/Charts/stats-bar";
import {Line} from "components/Charts/line";
import {ChartTable} from "components/Dashboard/chart-table";

export default function Dashboard() {
    const [top, setTop] = useState([])
    const [loading, setLoading] = useState(true)
    let {setTitle} = useContext(PageTitleContext)
    let {setCrumbs} = useContext(CrumbsContext)

    useEffect(() => {
        setTitle('Dashboard')
        setCrumbs([])
    }, [])

    useEffect(() => {
        setLoading(true)
        ApiConsumer.get(API_ROUTES.BROADCAST.REPORTS.ALL)
            .then(res => {
                setTop(res.data)
            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    }, [])

    return <>
        <div className="min-h-full px-5 py-5 mt-0">
            {/*<DashboardFilter details={details} setDetails={setDetails}/>*/}
            {!loading ?
                <h3 className='mb-1 font-semibold profile-overview-title'>This Months Top 5 Tracks</h3> : null
            }
            {
                loading ? <Loader upload_message={'loading'} full_height={true}/> :
                    <section className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-4 mt-3">
                        {
                            top.slice(0, 5).map((track, index) => {
                                return <MusicCard key={track.id} music={track} number={index + 1} can_play={false}/>
                            })
                        }
                    </section>
            }
            <section className="grid grid-cols-1 sm:grid-cols-4 gap-4 mt-5">
                <div className="sm:col-span-4">
                    <h3 className='mb-2 font-semibold profile-overview-title'>General Statistics</h3>
                    <StatsBar/>
                </div>
            </section>
            <section className="grid grid-cols-1 sm:grid-cols-4 gap-4 mt-5">
                <div className="sm:col-span-4">
                    <h3 className='mb-2 font-semibold profile-overview-title'>Recognition by Channel (Month vs Month)</h3>
                    <Line url={API_ROUTES.BROADCAST.REPORTS.COMPARISON.STATION_MONTHLY}/>
                </div>
                <div className="sm:col-span-4">
                    <h3 className='mb-2 font-semibold profile-overview-title'>Recognition per Channel (YTD)</h3>
                    <StackedLine url={API_ROUTES.BROADCAST.REPORTS.COMPARISON.STATION}/>
                </div>
            </section>

            <section className="grid grid-cols-1 xl:grid-cols-1 2xl:grid-cols-4 gap-4 mt-5">
                <div className="sm:col-span-2">
                    <h3 className='mb-2 font-semibold profile-overview-title'>Recognition by Month (Total All Channels)</h3>
                    <StackedLine url={API_ROUTES.BROADCAST.REPORTS.COMPARISON.BY_MONTH} title={'right'}/>
                </div>

                <div className="col-span-2 grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="sm:col-span-1">
                        <h3 className='mb-2 font-semibold profile-overview-title'>Unrecognised vs Recognised (MTD)</h3>
                        <Doughnut url={API_ROUTES.BROADCAST.REPORTS.COMPARISON.ALL}/>
                    </div>
                    <div className="sm:col-span-1">
                        <h3 className='mb-2 font-semibold profile-overview-title'>International vs National (MTD)</h3>
                        <Bar url={API_ROUTES.BROADCAST.REPORTS.COMPARISON.LOCATION} title={'left'}/>
                    </div>
                </div>
            </section>

            <section className="grid grid-cols-1 xl:grid-cols-1 2xl:grid-cols-2 gap-4 mt-5">
                <div className="col-span-1">
                    <h3 className='mb-2 font-semibold profile-overview-title'>Top 5 Tracks on TV (MTD)</h3>
                    <ChartTable url={API_ROUTES.BROADCAST.REPORTS.BY_TYPE('TV')} title={'right'}/>
                </div>
                <div className="col-span-1">
                    <h3 className='mb-2 font-semibold profile-overview-title'>Top 5 Tracks on Radio (MTD)</h3>
                    <ChartTable url={API_ROUTES.BROADCAST.REPORTS.BY_TYPE('RADIO')} title={'left'}/>
                </div>
            </section>
            <section className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-5">
                <div className="sm:col-span-1">
                    <h3 className='mb-2 font-semibold profile-overview-title'>Top 5 Artists (MTD)</h3>
                    <Doughnut url={API_ROUTES.BROADCAST.REPORTS.TOP_ARTISTS}/>
                </div>
                <div className="sm:col-span-1">
                    <h3 className='mb-2 font-semibold profile-overview-title'>Top 5 Labels (MTD)</h3>
                    <Doughnut url={API_ROUTES.BROADCAST.REPORTS.TOP_LABELS}/>
                </div>
            </section>
        </div>
    </>
}
