import html2canvas from "html2canvas";

const downloadFile = (name, data) => {
    if (typeof (data) === "string") {
        console.log('string')
        getFileData(name, data)
    } else {
        download(name, data)
    }
};

function download(name, data) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(new Blob([data]));
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
}

function getFileData(name, url) {
    fetch(url, {method: 'GET'})
        .then(res => {
            return res.blob();
        })
        .then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = name;
            document.body.appendChild(a);
            a.click();
            setTimeout(
                _ => {
                    window.URL.revokeObjectURL(url);
                },
                60000);
            a.remove();
        })
        .catch(err => {
            console.error('err: ', err);
        })
}

function getMonth(number) {
    if (typeof number === 'string')
        return number
    const months = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    return months[number]
}


const exportAsImage = async (element, imageFileName) => {
    const html = document.getElementsByTagName("html")[0];
    const body = document.getElementsByTagName("body")[0];
    let htmlWidth = html.clientWidth;
    let bodyWidth = body.clientWidth;
    const newWidth = element.scrollWidth - element.clientWidth;
    if (newWidth > element.clientWidth) {
        htmlWidth += newWidth;
        bodyWidth += newWidth;
    }
    html.style.width = htmlWidth + "px";
    body.style.width = bodyWidth + "px";
    const canvas = await html2canvas(element);
    const image = canvas.toDataURL("image/png", 1.0);
    downloadImage(image, imageFileName);
    html.style.width = null;
    body.style.width = null;
};
const downloadImage = (blob, fileName) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
};

export const useUtils = () => {
    return {downloadFile, getMonth, exportAsImage}
}
