import Loader from "components/Loader";

export function QueryTable({loading = true, data = [], setDetails={}, setFilterId={}}) {

    return <>
        {loading ? <Loader upload_message={'loading'} full_height={true}/> :
            <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                <tr>
                    <th scope="col"
                        className="py-3.5 pl-2 pr-3 text-left text-sm font-semibold text-blue-500 sm:pl-6">
                        Title
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Count
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Total Duration
                    </th>
                </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                {
                    data.map(track => (
                        <tr key={track?.id} className={'cursor-pointer'}>
                            <td className="whitespace-nowrap py-4 pl-1 pr-3 text-sm font-medium text-blue-500 sm:pl-6"
                                onClick={() => {
                                    setDetails((prevState) => ({
                                        ...prevState,
                                        [`term`]: track?.title
                                    }))
                                    setFilterId(track?.title)
                                }}>
                                {track.title}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{track?.count}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{track?.play_duration}</td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        }
        {
            !loading ? data.length === 0 ?
                <div className={'h-screen text-center overflow-hidden overflow-y-hidden overflow-x-hidden'}>
                    No results
                </div> : null : null
        }
    </>
}
