import {useEffect, useState} from "react";
import {ApiConsumer} from "api/ApiConsumer";
import {Chart} from "react-google-charts";

export function Bar({url, title = '', height = '400'}) {
    const [data, setData] = useState([])

    const colours = ['#5fa83f', '#f72585', '#118ab2', '#ff9770', '#d35400']

    const options = {
        chart: {
            title: title,
            subtitle: ''
        },
        legend: {position: 'none'},
        width: '100%',
        height: '400px'
    }

    useEffect(() => {
        ApiConsumer.get(url)
            .then(res => {
                if (Array.isArray(res.data)){
                    let temp = res.data.slice(0, 5)
                    let result = [];
                    result.push(["Track", "Plays", {role: "style"}])
                    Object.keys(res.data.slice(0, 5)).forEach(function (key, i) {
                        result.push([`${res.data.slice(0, 5)[key][Object.keys(temp[0])[0]]} - ${res.data.slice(0, 5)[key][Object.keys(temp[0])[1]]}`, res.data.slice(0, 5)[key][Object.keys(temp[0])[5]], colours[i]]);
                    });
                    setData(result)
                } else {
                    let result = [];
                    let temp = res.data;
                    result.push(["Track", "Plays", {role: "style"}])
                    Object.keys(res.data).forEach(function (key, i) {
                        result.push([key, res.data[key], colours[i]])
                    })
                    setData(result)
                }
            })
            .catch(err => console.error(err))
    }, [])

    return <>
        <Chart chartType="ColumnChart" width="100%" height={`${height}px`} data={data} options={options}
               className={'shadow-2xl rounded'}/>
    </>
}
