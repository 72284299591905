import APP_ROUTES, {ADMIN_ROUTES, BROADCAST_ROUTES, LIBRARY_ROUTES, REPORT_ROUTES} from 'routes/app';
import {NavLink, useHistory} from 'react-router-dom';
import imonitor from 'assets/images/imonitor.svg'
import cosbots from 'assets/images/cosbots-logo.png'
import {XCircleIcon} from '@heroicons/react/outline'
import {useAuthentication} from 'hooks/AuthenticationHook';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Sidebar() {
    let {logout} = useAuthentication()
    let {history} = useHistory()
    return (
        <div className='flex flex-col flex-grow border-r border-gray-200 pt-1 pb-0 border-gray-800 overflow-y-auto'>
            <div className='flex items-center flex-shrink-0 px-5 space-y-5 bg-white'>
                <img
                    className='h-10 w-auto mr-4'
                    src={imonitor}
                    alt='iMonitor'
                />
                <img
                    className='h-16 w-auto'
                    style={{paddingBottom: '10px'}}
                    src={cosbots}
                    alt='iMonitor'
                />
            </div>
            <div className='mt-5 flex-grow flex flex-col border-t-2 border-gray-800'>
                <nav className='flex-1 bg-gray-900 space-y-1' aria-label='Sidebar'>
                    <div className='mt-0 pt-2 border-t-2 border-gray-800'>
                        <div className='px-2 space-y-1'>
                            <span className='text-blue-500 font-medium text-xs ml-3'>
                              DASHBOARD
                            </span>
                        </div>
                    </div>
                    {APP_ROUTES.filter(x => x.sidebar === true).map((item) => {
                        return <NavLink
                            key={item.name}
                            to={item.link}
                            exact={item.name === 'Home'}

                            activeClassName={'bg-gray-400 border-blue-500 bg-gray-300 font-bold text-gray-900'}

                            className={'border-transparent text-white hover:bg-gray-200 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium border-l-4'}
                        >
                            <item.icon
                                className={classNames(
                                    (item.current) ? 'text-white' : 'text-gray-200 group-hover:text-gray-500',
                                    'mr-3 flex-shrink-0 h-6 w-6'
                                )}
                                aria-hidden='true'
                            />
                            {item.name}
                        </NavLink>
                    })
                    }
                    <div className='mt-1 pt-2 border-t-2 border-gray-800'>
                        <div className='px-2 space-y-1'>
                            <span className='text-blue-500 font-medium text-xs ml-3'>
                              MONITORING
                            </span>
                        </div>
                    </div>
                    {BROADCAST_ROUTES.filter(x => x.sidebar === true).map((item) => {
                        return <NavLink
                            key={item.name}
                            to={item.link}
                            exact={item.name === 'Home'}

                            activeClassName={'bg-gray-400 border-blue-500 bg-gray-300 font-bold text-gray-900'}

                            className={'border-transparent text-white hover:bg-gray-200 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium border-l-4'}
                        >
                            <item.icon
                                className={classNames(
                                    (item.current) ? 'text-gray-500' : 'text-gray-200 group-hover:text-gray-500',
                                    'mr-3 flex-shrink-0 h-6 w-6'
                                )}
                                aria-hidden='true'
                            />
                            {item.name}
                        </NavLink>
                    })
                    }
                    <div className='mt-6 pt-2 border-t-2 border-gray-800'>
                        <div className='px-2 space-y-1'>
                            <span className='text-blue-500 font-medium text-xs ml-3'>
                              LIBRARY
                            </span>
                        </div>
                    </div>
                    {LIBRARY_ROUTES.filter(x => x.sidebar === true).map((item) => {
                        return <NavLink
                            key={item.name}
                            to={item.link}
                            exact={item.name === 'Home'}

                            activeClassName={'bg-gray-400 border-blue-500 bg-gray-300 font-bold text-gray-900'}

                            className={'border-transparent text-white hover:bg-gray-200 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium border-l-4'}
                        >
                            <item.icon
                                className={classNames(
                                    item.current ? 'text-white' : 'text-gray-200 group-hover:text-gray-500',
                                    'mr-3 flex-shrink-0 h-6 w-6'
                                )}
                                aria-hidden='true'
                            />
                            {item.name}
                        </NavLink>
                    })
                    }
                    <div className='mt-6 pt-2 border-t-2 border-gray-800'>
                        <div className='px-2 space-y-1'>
                            <span className='text-blue-500 font-medium text-xs ml-3'>
                              CHARTS & REPORTS
                            </span>
                        </div>
                    </div>
                    {REPORT_ROUTES.filter(x => x.sidebar === true).map((item) => {
                        return <NavLink
                            key={item.name}
                            to={item.link}
                            exact={item.name === 'Home'}

                            activeClassName={'bg-gray-400 border-blue-500 bg-gray-300 font-bold text-gray-900'}

                            className={'border-transparent text-white hover:bg-gray-200 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium border-l-4'}
                        >
                            <item.icon
                                className={classNames(
                                    (item.current) ? 'text-indigo-500' : 'text-gray-200 group-hover:text-gray-500',
                                    'mr-3 flex-shrink-0 h-6 w-6'
                                )}
                                aria-hidden='true'
                            />
                            {item.name}
                        </NavLink>
                    })
                    }
                    <div className='mt-6 pt-2 border-t-2 border-gray-800'>
                        <div className='px-2 space-y-1'>
                            <span className='text-blue-500 font-medium text-xs ml-3'>
                              ADMINISTRATION
                            </span>
                        </div>
                    </div>
                    {ADMIN_ROUTES.filter(x => x.sidebar === true).map((item) => {
                        return <NavLink
                            key={item.name}
                            to={item.link}
                            exact={item.name === 'Home'}
                            activeClassName={'bg-gray-400 border-blue-500 bg-gray-300 font-bold text-gray-900'}

                            className={'border-transparent text-white hover:bg-gray-200 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium border-l-4'}
                        >
                            <item.icon
                                className={classNames(
                                    (item.current) ? 'text-indigo-500' : 'text-gray-200 group-hover:text-gray-500',
                                    'mr-3 flex-shrink-0 h-6 w-6'
                                )}
                                aria-hidden='true'
                            />
                            {item.name}
                        </NavLink>
                    })
                    }
                </nav>
            </div>
            <div className='flex-shrink-0 flex border-t-2 border-gray-800 p-5 bg-gray-900 cursor-pointer'
                 onClick={() => {
                     logout()
                     window.location.reload()
                     history.push('/login')
                     window.location.reload()
                 }}>
                <div className='flex-shrink-0 w-full group block'>
                    <div className='flex items-center'>
                        <div>
                            <XCircleIcon className='inline-block h-9 w-9 rounded-full text-red-600'/>
                        </div>
                        <div className='ml-2 pb-1'>
                            <p className='text-sm font-bold text-white group-hover:text-red-500'>Logout</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
