import Loader from "components/Loader";


export function CustomTable({list, limit = 1000000, loading = false}) {
    function formatSeconds(seconds) {
        let date = new Date();
        date.setSeconds(seconds);
        return date.toISOString().substr(11, 8);
    }

    function percentage(national, international) {
        const totalValue = national + international
        return ((100 * national) / totalValue).toFixed(2);
    }

    return <>
        {loading ? <Loader upload_message={'loading'} full_height={true}/> :
            <table className="w-full divide-y divide-gray-300 mb-5 shadow-xl rounded h-screen">
                <thead className="bg-gray-50">
                <tr>
                    <th scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 row-span-2">
                        Name
                    </th>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <tr className='border-b grid grid-cols-3'>
                            <div className='flex flex-row text-center w-full'>
                                <p className='flex flex-row text-center w-full text-gray-900 font-semibold'>National</p>
                            </div>
                        </tr>
                        <tr className="grid grid-cols-3 gap-4">
                            <td className='w-4/12 mr-2'>
                                <div className='flex flex-row'>
                                    Play Count
                                </div>
                            </td>
                            <td className='w-4/12 mr-2'>
                                <div className='flex flex-row'>
                                    Duration
                                </div>
                            </td>
                            <td className='w-4/12'>
                                <div className='flex flex-row'>
                                    Share
                                </div>
                            </td>
                        </tr>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <tr className='border-b grid grid-cols-3'>
                            <div className='flex flex-row text-center w-full'>
                                <p className='flex flex-row text-center w-full text-gray-900 font-semibold'>International</p>
                            </div>
                        </tr>
                        <tr className="grid grid-cols-3 gap-4">
                            <div className='w-4/12 mr-2'>
                                <div className='flex flex-row'>
                                    Play Count
                                </div>
                            </div>
                            <div className=''>
                                <div className='flex flex-row'>
                                    Duration
                                </div>
                            </div>
                            <div className='w-4/12'>
                                <div className='flex flex-row'>
                                    Share
                                </div>
                            </div>
                        </tr>
                    </td>
                </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                {list.slice(0, limit).map((item, i) => (
                    <tr key={item.title}>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {item?.broadcast_stream__name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <tr className="grid grid-cols-3 gap-4">
                                <td className='w-4/12'>
                                    <div className='flex flex-row'>
                                        {item?.national}
                                    </div>
                                </td>
                                <td className='w-4/12'>
                                    <div className='flex flex-row'>
                                        {formatSeconds(item?.national_duration)}
                                    </div>
                                </td>
                                <td className='w-4/12'>
                                    <div className='flex flex-row'>
                                        {percentage(item?.national_duration, item?.international_duration)} %
                                    </div>
                                </td>
                            </tr>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <tr className="grid grid-cols-3 gap-4">
                                <td className='w-4/12'>
                                    <div className='flex flex-row'>
                                        {item?.international}
                                    </div>
                                </td>
                                <td className='w-4/12'>
                                    <div className='flex flex-row'>
                                        {formatSeconds(item?.international_duration)}
                                    </div>
                                </td>
                                <td className='w-4/12'>
                                    <div className='flex flex-row'>
                                        {percentage(item?.international_duration, item?.national_duration)} %
                                    </div>
                                </td>
                            </tr>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        }
    </>
}
