import { useEffect, useState } from "react";
import { ApiConsumer } from "api/ApiConsumer";
import { API_ROUTES } from "routes/api";
import Loader from "components/Loader";
import MusicCard from "components/Music/card";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";


export function MusicTable({ term = {} }) {
    const [library, setLibrary] = useState([])
    const [loading, setLoading] = useState(true)
    const [total, setTotal] = useState(0)
    const [currentPage, setPage] = useState(1);

    const pages = [];
    const numPages = Math.ceil(total / 18);

    for (let i = 1; i <= numPages; i++) {
        if (i <= 5 || i == numPages || Math.abs(currentPage - i) <= 1)
            pages.push(i);
    }

    function load() {
        setLoading(true)
        const filters = Object.assign(term, {
            'page': currentPage
        })
        ApiConsumer.get(API_ROUTES.LIBRARY.ALL, {
            params: filters,
            paramSerializer: function (params) {
                return JSON.stringify(params)
            }
        })
            .then(res => {
                setTotal(res.data.count)
                setLibrary(res.data.data)
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth"
                });
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        load()
    }, [currentPage])

    useEffect(() => {
        load()
    }, [term])

    return <>
        {
            loading ? <Loader upload_message={'loading'} full_height={true} /> : <>
                <section className="grid grid-cols-2 sm:grid-cols-4 xl:grid-cols-6 gap-4 mt-5">
                    {
                        library.map((track, index) => {
                            return <MusicCard key={track.id} music={track} can_play={true} />
                        })
                    }
                </section>
                <tbody className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between mt-5 divide-y divide-gray-200 bg-white p-4">
                    <div>
                        <p className="text-sm text-gray-700">
                            Showing <span className="font-medium">{currentPage * 18 - 18}</span> to <span
                                className="font-medium">{currentPage * 18}</span> of{' '}
                            <span className="font-medium">{total}</span> results
                        </p>
                    </div>
                    <div>
                        <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                            aria-label="Pagination">
                            <a
                                onClick={() => {
                                    if (currentPage > 1) {
                                        setPage(currentPage - 1)
                                    } else {
                                        setPage(currentPage)
                                    }
                                }}
                                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                            >
                                <span className="sr-only">Previous</span>
                                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                            </a>
                            {
                                pages.map(page => {
                                    return <button
                                        key={page}
                                        aria-current="page"
                                        onClick={() => {
                                            setPage(page)
                                        }}
                                        className={`z-10 ${currentPage === page ? "border-2 bg-indigo-50 border-indigo-500 text-indigo-600" : "border-gray-300 text-gray-500 hover:bg-gray-200"} relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                                    >
                                        {page}
                                    </button>
                                })
                            }
                            <a
                                onClick={() => {
                                    if (currentPage >= pages[pages.length - 1]) {
                                        setPage(currentPage)
                                    } else {
                                        setPage(currentPage + 1)
                                    }
                                }}
                                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                            >
                                <span className="sr-only">Next</span>
                                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                            </a>
                        </nav>
                    </div>
                </tbody>
            </>
        }
    </>
}
