import Login from "pages/login";
import Dashboard from "pages/dashboard";
import Monitoring from "pages/monitoring";
import Uploads from "pages/uploads";
import Library from "pages/library";
import Scan from "pages/scan";
import Reports from "pages/reports";
import {
    HomeIcon,
    VideoCameraIcon,
    MusicNoteIcon,
    LibraryIcon,
    SearchCircleIcon,
    ChartPieIcon,
    PresentationChartBarIcon,
    UserGroupIcon,
    KeyIcon,
    BookOpenIcon,
    DeviceMobileIcon, ViewListIcon, QrcodeIcon
} from "@heroicons/react/outline";
import Broadcast from "pages/broadcast";
import Venue from "pages/venue";
import Profile from "pages/profile";
import Charts from "pages/charts";
import Users from "pages/users";
import UserGuide from "pages/guide";
import Access from "pages/access";
import PlaylistReport from "pages/playlist-report";
import Query from "pages/query";
import ForgotPassword from "pages/forgot-password";
import CustomReport from "pages/custom-report";
import {DocumentReportIcon} from "@heroicons/react/solid";

const APP_ROUTES = [
    {
        name: 'Login',
        link: '/login',
        page: Login,
        exact: true,
        private: false,
        sidebar: false,
        icon: HomeIcon
    },{
        name: 'Login',
        link: '/register',
        page: Access,
        exact: true,
        private: false,
        sidebar: false,
        icon: HomeIcon
    },
    {
        name: 'Home',
        link: '/',
        page: Dashboard,
        exact: true,
        private: true,
        sidebar: true,
        icon: HomeIcon
    },
    {
        name: 'Broadcast Detail',
        link: '/monitoring/:id',
        page: Broadcast,
        exact: true,
        private: true,
        sidebar: false,
        icon: null
    },
    {
        name: 'Forgot Password',
        link: '/forgot-password',
        page: ForgotPassword,
        exact: true,
        private: false,
        sidebar: false,
        icon: null
    }
]
export default APP_ROUTES
export const LIBRARY_ROUTES = [
    {
        name: 'Music Upload',
        link: '/uploads',
        page: Uploads,
        exact: false,
        private: true,
        sidebar: true,
        icon: MusicNoteIcon
    },
    {
        name: 'Digital Library',
        link: '/library',
        page: Library,
        private: true,
        sidebar: true,
        icon: LibraryIcon
    }
]
export const BROADCAST_ROUTES = [
    {
        name: 'Broadcast Monitoring',
        link: '/monitoring',
        page: Monitoring,
        exact: true,
        private: true,
        sidebar: true,
        icon: VideoCameraIcon
    },
    {
        name: 'Offline Scan',
        link: '/scan',
        page: Scan,
        exact: false,
        private: true,
        sidebar: true,
        icon: SearchCircleIcon
    },
    // {
    //     name: 'Venue Monitoring',
    //     link: '/venue',
    //     page: Venue,
    //     exact: false,
    //     private: true,
    //     sidebar: true,
    //     icon: DeviceMobileIcon
    // },
    {
        name: 'Unrecognised Querying',
        link: '/query',
        page: Query,
        exact: false,
        private: true,
        sidebar: true,
        icon: QrcodeIcon
    }
]
export const REPORT_ROUTES = [
    {
        name: 'Broadcast Reports',
        link: '/reports/broadcast',
        page: Reports,
        exact: false,
        private: true,
        sidebar: true,
        icon: PresentationChartBarIcon
    },
    {
        name: 'Playlist Reports',
        link: '/reports/playlist',
        page: PlaylistReport,
        exact: false,
        private: true,
        sidebar: true,
        icon: ViewListIcon
    },
    {
        name: 'Custom Reports',
        link: '/reports/custom',
        page: CustomReport,
        exact: false,
        private: true,
        sidebar: true,
        icon: DocumentReportIcon
    },
    {
        name: 'Music Charts',
        link: '/reports/charts',
        page: Charts,
        exact: false,
        private: true,
        sidebar: true,
        icon: ChartPieIcon
    }
]
export const ADMIN_ROUTES = [
    {
        name: 'Users',
        link: '/users',
        page: Users,
        exact: false,
        private: true,
        sidebar: true,
        icon: UserGroupIcon
    },
    {
        name: 'Your Account',
        link: '/account',
        page: Profile,
        exact: false,
        private: true,
        sidebar: false,
        icon: KeyIcon
    },
    // {
    //     name: 'System Activity',
    //     link: '/activity',
    //     page: Venue,
    //     exact: false,
    //     private: true,
    //     sidebar: true,
    //     icon: CursorClickIcon
    // },
    {
        name: 'User Guides',
        link: '/guide',
        page: UserGuide,
        exact: false,
        private: true,
        sidebar: true,
        icon: BookOpenIcon
    }
]

