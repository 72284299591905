import {useEffect, useState} from "react";
import {ApiConsumer} from "api/ApiConsumer";
import {API_ROUTES} from "routes/api";
import Loader from "components/Loader";
import {ChartTable} from "components/Dashboard/chart-table";

export function BroadcastHeader({broadcast}) {
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        if (broadcast !== undefined)
            ApiConsumer.get(API_ROUTES.BROADCAST.CHARTS(broadcast?.id))
                .then(res => setList(res.data.splice(0, 5)))
                .catch(err => console.error(err))
                .finally(() => setLoading(false))
    }, [broadcast])

    return <>
        <div className="grid grid-cols-4 gap-4">
            <div>
                <div className="rounded overflow-hidden mb-6 shadow-xl">
                    <h3 className='mb-2 font-semibold profile-overview-title'>Station Overview</h3>
                    <div className="w-full">
                        <div
                            className="c-card block bg-white shadow-xl hover:shadow-2xl rounded overflow-hidden hover:border-blue-500 p-3">
                            <div className="relative pb-48 overflow-hidden">
                                <img className="absolute inset-0 h-full w-full object-cover"
                                     src={broadcast?.logo}
                                     alt="stream"/>
                            </div>
                            <div className="pt-3">
                                <h2 className="font-bold">{broadcast?.name}</h2>
                            </div>
                            <dl className="mt-2 divide-y divide-gray-200 border-t border-gray-200 pb-2">
                                <div className="flex justify-between py-3 text-sm font-medium pr-2">
                                    <dt className="text-gray-500">Monitoring Since</dt>
                                    <dd className="text-gray-900">{broadcast?.created?.slice(0, 16)?.replace('T', ' ')}</dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>

            <div className='col-span-3 rounded'>
                <h3 className='mb-2 font-semibold'>Top 5 Chart</h3>
                {
                    loading ? <Loader upload_message={'loading'} full_height={false}/> :
                        <ChartTable url={API_ROUTES.BROADCAST.CHARTS(broadcast?.id)} title={'left'} height={313}/>
                }
            </div>
        </div>
    </>
}
