import {useContext, useEffect, useState} from "react";
import {PageTitleContext} from "context/PageTitleContext";
import {CrumbsContext} from "context/CrumbsContext"
import {SearchIcon} from "@heroicons/react/outline";
import QueryFilter from "components/Reports/QueryFilter";
import {ApiConsumer} from "api/ApiConsumer";
import {API_ROUTES} from "routes/api";
import {QueryTable} from "components/Query/table";
import {ResultsTable} from "components/Query/results";

export default function Query() {
    const [details, setDetails] = useState({type: ['unrecognised']})
    const [loading, setLoading] = useState(false)
    const [results, setResults] = useState([])
    const [filterId, setFilterId] = useState('')
    const [filterLoading, setFilterLoading] = useState(false)
    const [occurances, setOccurances] = useState([])


    let {setTitle} = useContext(PageTitleContext)
    let {setCrumbs} = useContext(CrumbsContext)

    function searchReport() {
        setLoading(true)
        ApiConsumer.get(API_ROUTES.BROADCAST.REPORTS.CHARTS, {
            params: {...details, page: 1}, paramSerializer: function (params) {
                return JSON.stringify(params)
            }
        })
            .then(res => {
                setResults(res.data)
            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        setTitle('Unrecognised Querying')
        setCrumbs([{'name': 'Monitoring', 'link': '/monitoring'}, {'name': 'Unrecognised Querying', 'link': '/query'}])
    }, [])

    useEffect(()=>{
        setFilterLoading(true)
        ApiConsumer.get(API_ROUTES.BROADCAST.REPORTS.BROADCAST, {
            params: {...details, page: 1}, paramSerializer: function (params) {
                return JSON.stringify(params)
            }
        })
            .then(res => {
                setOccurances(res.data.data)
            })
            .catch(err => console.error(err))
            .finally(() => setFilterLoading(false))
    }, [filterId])

    return <>
        <div className="min-h-full px-5 py-5 mt-0">
            <QueryFilter details={details} setDetails={setDetails}/>
            <h3 className='mb-2 font-semibold profile-overview-title mt-5 flex mx-full'>
                <span className="min-w-0 flex-1 items-center"/>
                <SearchIcon className="h-8 w-8 pt-1.5 cursor-pointer hover:text-green-500" aria-hidden="true"
                            onClick={searchReport}/>
            </h3>
            <div className="border-b border-gray-200 grid grid-cols-12 pb-3 gap-2">
                <div className="col-span-4 w-full">
                    <h3 className='mb-2 font-semibold profile-overview-title'>Items</h3>
                    <QueryTable data={results} loading={loading} setDetails={setDetails} setFilterId={setFilterId}/>
                </div>
                <div className="col-span-8 w-full">
                    <h3 className='mb-2 font-semibold profile-overview-title'>Occurrences - {details?.term}</h3>
                    <ResultsTable data={occurances} loading={filterLoading} query={true}/>
                </div>
            </div>
        </div>
    </>
}
