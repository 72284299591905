import {CogIcon} from "@heroicons/react/outline";

export default function Loader({upload_message, full_height = false}) {
    return (
        <div
            className={`self-auto text-center place-content-center mt-5 pt-5 mb-5 pb-5 rowspan-7 ${full_height ? "h-screen" : ""}`}>
            <section
                className={`hero container max-w-screen-lg mx-auto pb-10 text-blue-500`}>
                <CogIcon className="animate-spin h-6 w-6 content-center text-center mx-auto"/>
                {upload_message}
            </section>
        </div>
    );
}
