import Loader from "components/Loader";
import {useEffect, useState} from 'react';
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/solid";

export function MusicChart({
                               list,
                               limit = 20,
                               loading = false,
                               include_count = false,
                               report = false,
                               ordered_chart = false
                           }) {
    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage, setPostsPerPage] = useState(limit)

    const indexOfLastPost = currentPage * postsPerPage
    const indexOfFirstPost = indexOfLastPost - postsPerPage
    const currentPosts = list.slice(indexOfFirstPost, indexOfLastPost)

    useEffect(()=>setPostsPerPage(limit),[limit])

    const paginateFront = () => {
        if (currentPage < list.length) {
            setCurrentPage(currentPage + 1)
        }
    }
    const paginateBack = () => {
        if (currentPage * postsPerPage - limit > 0) {
            setCurrentPage(currentPage - 1)
        }
    }
    const pages = [];
    const numPages = Math.ceil(list.length / postsPerPage);

    for (let i = 1; i <= numPages; i++) {
        if (i <= 5 || i == numPages || Math.abs(currentPage - i) <= 1)
            pages.push(i);
    }

    return <div className={'rounded shadow-lg'}>
        {loading ? <Loader upload_message={'loading'} full_height={true}/> :
            <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                <tr>
                    {
                        include_count ? <th scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            No.
                        </th> : null
                    }
                    <th scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        {ordered_chart ? 'Name' : 'Title'}
                    </th>
                    {
                        !ordered_chart ? <>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Artist
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Album
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Label
                            </th>
                        </> : null
                    }
                    {
                        report ? <>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Count
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Duration
                            </th>
                        </> : null
                    }
                </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                {currentPosts.slice(0, limit).map((item, i) => (
                    <tr key={item.title}>
                        {
                            include_count ?
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    {i + 1}
                                </td> : null
                        }
                        {
                            ordered_chart ?
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item?.artist ?? item?.label}</td>
                                : null
                        }
                        {
                            !ordered_chart ? <>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    {item?.title?.slice(0, 20)}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item?.artist?.slice(0, 20)}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item?.album?.slice(0, 20)}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item?.label?.slice(0, 20)}</td>
                            </> : null
                        }
                        {
                            report ? <>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item?.count}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item?.play_duration}</td>
                            </> : null
                        }
                    </tr>
                ))}
                </tbody>
            </table>
        }
        {
            !loading ? list.length === 0 ?
                <div className={'h-screen text-center overflow-hidden overflow-y-hidden overflow-x-hidden'}>
                    No results
                </div> : null : null
        }
        {
            limit > 100 ?

                limit !== 5 ?
                    <div
                        className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                        <div className="flex-1 flex justify-between sm:hidden">
                            <a
                                href="#"
                                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            >
                                Previous
                            </a>
                            <a
                                href="#"
                                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            >
                                Next
                            </a>
                        </div>
                        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                            <div>
                                <p className="text-sm text-gray-700">
                                    Showing <span
                                    className="font-medium">{currentPage * postsPerPage - limit}</span> to <span
                                    className="font-medium">{(currentPage * postsPerPage) > list.length ? list.length : currentPage * postsPerPage}</span> of&nbsp;
                                    <span className="font-medium">{list.length}</span> results
                                </p>
                            </div>
                            <div>
                                <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                                     aria-label="Pagination">
                                    {
                                        currentPage <= pages[0] ? <a href="#"
                                                                     className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                        >
                                            <span className="sr-only">Previous</span>
                                            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true"/>
                                        </a> : <a onClick={() => {
                                            paginateBack();
                                        }}
                                                  className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                        >
                                            <span className="sr-only">Previous</span>
                                            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true"/>
                                        </a>
                                    }

                                    {
                                        pages.map(page => {
                                            return <button
                                                key={page}
                                                aria-current="page"
                                                onClick={() => {
                                                    setCurrentPage(page)
                                                }}
                                                className={`z-10 ${currentPage === page ? "border-2 bg-indigo-50 border-indigo-500 text-indigo-600" : "border-gray-300 text-gray-500 hover:bg-gray-200"} relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                                            >
                                                {page}
                                            </button>
                                        })
                                    }

                                    {
                                        currentPage >= pages[pages.length - 1] ? <a href="#"
                                                                                    className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                        >
                                            <span className="sr-only">Next</span>
                                            <ChevronRightIcon className="h-5 w-5" aria-hidden="true"/>
                                        </a> : <a onClick={() => {
                                            paginateFront();
                                        }}
                                                  className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                        >
                                            <span className="sr-only">Next</span>
                                            <ChevronRightIcon className="h-5 w-5" aria-hidden="true"/>
                                        </a>
                                    }
                                </nav>
                            </div>
                        </div>
                    </div> : null : null
        }
    </div>
}
