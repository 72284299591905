import {useEffect, useState} from "react";
import {ApiConsumer} from "api/ApiConsumer";
import {API_ROUTES} from "routes/api";


export function StatsBar() {
    const [data, setData] = useState([])
    useEffect(() => {
        ApiConsumer.get(API_ROUTES.CONTENT.STATS)
            .then(res => {
                let temp = []
                temp.push({
                    name: 'Library',
                    stat: res.data?.library_total?.toLocaleString('en-US'),
                    wording: `items ingested, ${res.data?.library_total?.toLocaleString('en-US')} fingerprinted.`
                })
                temp.push({
                    name: 'Users',
                    stat: res.data?.users_total,
                    wording: `registered, ${res.data?.users_inactive} inactive`
                })
                temp.push({name: 'Recognised Percentage', stat: '98.8%', wording: ''})
                temp.push({
                    name: 'Venues Monitored',
                    stat: res.data?.venue_devices,
                    wording: `devices online, 0 offline`
                })
                temp.push({
                    name: 'Stations Monitored',
                    stat: res.data?.stations_total,
                    wording: `stations online`
                })
                setData(temp)
            })
    }, [])
    return (
        <div className={'mb-5 shadow-lg bg-white'}>
            <dl className="mt-1 grid grid-cols-1 bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-5 md:divide-y-0 md:divide-x">
                {data.map((item) => (
                    <div key={item.name} className="px-4 py-5 sm:p-6">
                        <dt className="text-base font-normal text-gray-900">{item.name}</dt>
                        <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                            <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                                {item.stat}
                                <span className="ml-2 text-sm font-medium text-gray-500">{item?.wording}</span>
                            </div>
                        </dd>
                    </div>
                ))}
            </dl>
        </div>
    )
}
