import {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/outline";
import {ApiConsumer} from "api/ApiConsumer";
import {API_ROUTES} from "routes/api";
import Loader from "components/Loader";

export function BroadcastCard({broadcast}) {
    const [top10, setTop10] = useState([])
    const [loading, setLoading] = useState(true)
    console.log(broadcast)

    useEffect(() => {
        setLoading(true)
        ApiConsumer.get(API_ROUTES.BROADCAST.MONITORING(broadcast.id))
            .then(res => setTop10(res.data.data.splice(0, 6)))
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    }, [broadcast])

    return <>
        <div className="w-full">
            <NavLink to={`/monitoring/${broadcast?.id}`}
                     className="c-card block bg-white shadow-xl hover:shadow-2xl rounded overflow-hidden hover:border-2 border-transparent border-2 hover:border-blue-500 p-3">
                <div className="relative pb-48 overflow-hidden">
                    <img className="absolute inset-0 h-full w-full object-cover md:w-72 block rounded"
                         src={broadcast?.logo}
                         alt="stream"/>
                </div>
                <div className="pt-3 pb-1">
                    <h2 className="font-bold">{broadcast?.name}</h2>
                </div>
                <div className="mt-0 flex items-center">
                    <span className="pl-0 text-xs font-semibold text-gray-900 mt-2">Latest Tracks</span>
                </div>
                <dl className="mt-2 divide-y divide-gray-200 border-t border-b border-gray-200">
                    {
                        loading ? <Loader upload_message={'loading'}/> :
                            top10.map(track => {
                                return <div className="flex justify-between py-3 text-sm font-medium pl-0 pr-2">
                                    <dt className="text-gray-500">{`${track?.title?.slice(0, 15)}${track?.title?.length >= 20 ? "..." : ""}`}</dt>
                                    <dd className="text-gray-900">{`${track?.artist?.slice(0, 20)}`}</dd>
                                </div>
                            })
                    }
                </dl>
                <div className="pt-1 border-t-2">
                    <div className="flex justify-between mb-1">
                        <span className='neg-m-2'>
                            <span
                                className="inline-flex uppercase items-center rounded text-xs font-medium text-white">
                                {
                                    broadcast.streaming ? <CheckCircleIcon className={'h-5 text-green-500 pr-1'}/> : <XCircleIcon className={'h-5 text-red-500 pr-1'}/>
                                }
                            </span>
                            Status
                        </span>
                        <span>
                            <span
                                className="inline-flex uppercase items-centerrounded text-xs font-medium text-white">
                                {
                                    broadcast.monitoring ? <CheckCircleIcon className={'h-5 text-green-500 pr-1'}/> : <XCircleIcon className={'h-5 text-red-500 pr-1'}/>
                                }
                            </span>
                            Tracking
                        </span>
                    </div>
                </div>
            </NavLink>
        </div>
    </>
}
