import {createContext} from "react";
import Logo from 'assets/images/imonitor.svg'

export const PlayerProperties = {
    isPlaying: false,
    isPaused: false,
    isStopped: true,
    playing: {
        id: '',
        image: Logo,
        title: '',
        artist: '',
        link: null,
        region: null
    },
    setPlaying: () => {
    }
}

export const MusicPlayerContext = createContext(PlayerProperties);

