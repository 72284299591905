import {useState, useEffect, useContext} from 'react'
import {NavLink, useHistory} from 'react-router-dom'
import {API_ROUTES} from 'routes/api'
import {NotificationContext} from "context/NotificationContext";
import imonitor from "assets/images/imonitor.svg";
import cosbots from "assets/images/cosbots-logo.png";
import {CogIcon} from "@heroicons/react/outline";
import axios from "axios";

export default function ForgotPassword() {
    const [details, setDetails] = useState({})
    const [formRequested, setFormRequested] = useState(false)
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [loading, setLoading] = useState(false)

    let {setNotification} = useContext(NotificationContext)
    let history = useHistory()

    function forgot() {
        setLoading(true)
        if (formRequested) {
            axios.put(API_ROUTES.AUTHENTICATION.FORGOT_PASSWORD, details)
                .then((res) => {
                    setNotification({
                        show: true,
                        title: 'Password Reset',
                        message: `Your password has been successfully reset.`,
                        type: 'success'
                    })
                    history.push('/')
                })
                .catch((err) => {
                    console.error(err)
                })
                .finally(() => setLoading(false))
        } else {
            axios.post(API_ROUTES.AUTHENTICATION.PASSWORD_TOKEN, details)
                .then((res) => {
                    if (res.data.token === undefined || res.data.token === null) {
                        setDetails((prevState) => ({
                            ...prevState,
                            [`token`]: '',
                        }))
                    } else {
                        setDetails((prevState) => ({
                            ...prevState,
                            [`token`]: res.data.token,
                        }))
                    }
                    setNotification({
                        show: true,
                        title: 'Request sent',
                        message: `Please check your email for OTP.`,
                        type: 'success'
                    })
                    setFormRequested(true)
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => setLoading(false))

        }
    }

    return (
        <>
            <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        className="mx-auto h-12 w-auto"
                        src={imonitor}
                        alt="iMonitor Logo"
                    />
                    <img
                        className="mx-auto h-12 w-auto mt-5"
                        src={cosbots}
                        alt="COSBOTS Logo"
                    />
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to iMonitor</h2>
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow-lg sm:rounded-lg sm:px-10 border-2">
                        {!formSubmitted ? (
                            <form
                                className="mt-8 space-y-6"
                                onSubmit={(event) => {
                                    event.preventDefault()
                                    forgot()
                                }} method="POST"
                            >
                                <div className="space-y-1">
                                    <div>
                                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                            Email address
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                autoComplete="email"
                                                required
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                onChange={(event) => {
                                                    setDetails((prevState) => ({
                                                        ...prevState,
                                                        [`email`]: event.target.value,
                                                    }))
                                                }}
                                                value={details?.email}
                                            />
                                        </div>
                                    </div>
                                    {formRequested && (
                                        <>
                                            <div>
                                                <label htmlFor="email"
                                                       className="block text-sm font-medium text-gray-700">
                                                    OTP
                                                </label>
                                                <input
                                                    id="otp"
                                                    name="otp"
                                                    type="otp"
                                                    required
                                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                    onChange={(event) => {
                                                        setDetails((prevState) => ({
                                                            ...prevState,
                                                            [`otp`]: event.target.value,
                                                        }))
                                                    }}
                                                    value={details?.otp}
                                                />
                                            </div>

                                            <div>
                                                <label htmlFor="email"
                                                       className="block text-sm font-medium text-gray-700">
                                                    New Password
                                                </label>
                                                <input
                                                    id="password"
                                                    name="password"
                                                    type="password"
                                                    required
                                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                    onChange={(event) => {
                                                        setDetails((prevState) => ({
                                                            ...prevState,
                                                            [`password`]: event.target.value,
                                                        }))
                                                    }}
                                                    value={details.password}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>

                                <div>
                                    <button
                                        type="submit"
                                        disabled={loading}
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        {
                                            loading ? <CogIcon
                                                    className="animate-spin h-6 w-6 content-center text-center mx-auto"/> :
                                                <>{formRequested ? 'Submit' : 'Request OTP'}</>
                                        }
                                    </button>
                                </div>
                            </form>
                        ) : (
                            <>
                                <div>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setFormSubmitted(false)
                                            setFormRequested(false)
                                        }}
                                        className="button m-auto"
                                    >
                                        Submit Again
                                    </button>
                                </div>
                            </>
                        )}

                        <div className="flex items-center justify-between text-center mt-2 text-center">
                            <div className="text-sm text-center">
                                <NavLink to={'/login'}
                                         className="font-medium text-center text-indigo-600 hover:text-indigo-500">
                                    Return to login
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
