import {Chart} from "react-google-charts";
import {useEffect, useState} from "react";
import {ApiConsumer} from "api/ApiConsumer";
import Loader from "components/Loader";

export function Doughnut({url = ''}) {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    const colours = ['#5fa83f', '#f72585', '#118ab2', '#ff9770', '#d35400']

    useEffect(() => {
        setLoading(true)
        ApiConsumer.get(url)
            .then(res => {
                let temp = []
                if (!Array.isArray(res.data)) {
                    Object.keys(res.data).map(key => {
                        temp.push({
                            "name": key,
                            "count": res.data[key]
                        })
                    })
                } else {
                    temp = res.data.slice(0, 5)
                }
                let result = [];
                result.push(["Plays", "Within Period Specified", {role: "style"}])
                const keys = Object.keys(temp);
                keys.forEach(function (key, i) {
                    result.push([temp.slice(0, 5)[key][Object.keys(temp.slice(0, 5)[0])[0]], temp.slice(0, 5)[key][Object.keys(temp[0])[1]], colours[i]]);
                });
                setData(result)
            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    }, [url])

    const options = {
        title: "",
        pieHole: 0.4,
        is3D: false,
        legend: {position: 'bottom'},
    };

    return <>
        {loading ? <Loader upload_message={'loading'} full_height={true}/> :
            <Chart
                chartType="PieChart"
                width="100%"
                height="400px"
                data={data}
                options={options}
                className={'shadow-2xl rounded'}
            />
        }
    </>
}
